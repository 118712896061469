import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function News() {
    document.title = 'AnimeGuess - News'
    return (
        <div>
          <Container>
                <Typography id="transition-modal-title" variant="h4" component="h2">
                  <p style={{ fontFamily: 'Hahmlet', textAlign: "center" }}>News</p>
                </Typography>
                <Typography>
                <p style={{ fontFamily: 'Zen Kurenaido', fontSize: 20}}>14 November, 2021</p> 
                <p>Anime update. These titles were added: Kimetsu no Yaiba, Nisekoi.</p>
                <p>Android app released, but we don't have a presence on Google Play Store yet. I'll register for one when I have the time. For now, you'll have to download the APK at the top corner if you're interested.</p>
                <p>We got our first user from Italy. Yay! Welcome, Italian.</p>
                <p>Disqus Comments.</p>
                </Typography>
                <Typography>
                <p style={{ fontFamily: 'Zen Kurenaido', fontSize: 20}}>31 October, 2021</p> 
                <p>Anime update. These titles were added: Fruits Basket, Steins;Gate, Violet Evergarden, Gintama, 3-Gatsu no Lion, JoJo's Bizarre Adventure, Attack on Titan - Shingeki no Kyojin, Your Name, Takt Op. Destiny, Kingdom, Mob Psycho 100, Ayakashi Triangle, Haikyuu, Spirited Away, To Your Eternity, GTO, Princess Mononoke - Mononoke Hime, One-Punch Man, Boarding School Juliet, Haruhi Suzumiya, Kaguya-sama wa Kokurasetai?: Tensai-tachi no Renai Zunousen, Natsume Yuujinchou, Monster. 
                </p>
                <p>Disqus Comments.</p>
                </Typography>
                <Typography>
                <p style={{ fontFamily: 'Zen Kurenaido', fontSize: 20}}>26 October, 2021</p> 
                <p><i>AnimeGuess</i> was born.</p>
                </Typography>
          </Container>
        </div>
    )
}