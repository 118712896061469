import React, { useRef, useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import CheckMobile from '../tools/checkmobile'
import Typography from '@mui/material/Typography';

function Disqus(props) {
  var url_string = window.location.href
  var url = new URL(url_string);
  var id = url.searchParams.get("id");
  
  useEffect(() => {
    document.title = "AnimeGuess/Disqus - " + id
  }, [])

  var disqus_config = function () {
    this.page.url = "anime-guess.com/" + id  // Replace PAGE_URL with your page's canonical URL variable
    this.page.identifier = id; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
  };
  (function () { // DON'T EDIT BELOW THIS LINE
    var d = document, s = d.createElement('script');
    s.src = 'https://anime-guess.disqus.com/embed.js';
    s.setAttribute('data-timestamp', +new Date());
    (d.head || d.body).appendChild(s);
  })();
  return (
    <div>
      <Typography id="transition-modal-title" variant="h4" component="h2">
        <p style={{ fontFamily: 'Hahmlet', textAlign: "center" }}>{id}</p>
      </Typography>
      {/* <div id="disqus_thread"></div> */}
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1621207050229211"
        crossorigin="anonymous"></script>
      <noscript>Please enable JavaScript to view the <a href="https://disqus.com/?ref_noscript">comments powered by Disqus.</a></noscript>
      <div id="disqus_thread"></div>
    </div>
  );
}


export default Disqus;
