import React, { useState, useEffect } from 'react';
import { pens } from './personalities';
import SearchIcon from '@mui/icons-material/Search';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';
import {CheckMobile} from './tools/swissknife'
import {handleImageSrc} from './tools/swissknife'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60vw',
  padding: 2,
  overflow: 'scroll',
  bgcolor: 'background.paper',
};

const mobileModalStyle = {
  position: 'absolute',
  top: '0%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: '80vw',
  padding: 2,
  overflow: 'scroll',
  bgcolor: 'background.paper',
}


function Main() {
  const [gridPens, setPens] = useState(pens);
  const [query, setQuery] = useState("");
  const [openState, setOpen] = useState(false);
  const [currentPen, setPen] = useState(pens[0]);
  const [options, setOptions] = useState(pens);
  const [lang, setLang] = useState("en");
  const is_mobile = CheckMobile();


  useEffect(() => {
    handlePens();
    var new_options = convertPens(pens);
    setOptions(new_options);
  }, [])

  useEffect(() => {
    handlePens();
  }, [lang])

  function convertPens(raw_pens) {
    //Convert pens into list with alphabet and fav_list
    var new_options = raw_pens.map((option) => {
      const firstLetter = option.name[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });
    new_options = new_options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))

    const fav_list = raw_pens.filter(x => x.is_fav);

    fav_list.forEach(x => {
      new_options.unshift(x);
    })
    return new_options
  }

  function handlePens() {
    var flattenedPens = pens.map(element => {
      //Turn the array of descriptions into the description string with the set language
      var desc = element.description.find(x => x.lang == lang);
      if (desc != null)
        element.showDescription = desc.text;
      return element;
    });
    setPens(flattenedPens);
  }

  function onInputChange(e, value) {
    //Handle Search
    if (value != null && value != "" && value != "undefined") {
      var new_options = pens.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
      new_options = convertPens(new_options);
      //Remove Duplicates in Fav List and Normal List
      new_options = new_options.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i)
      setOptions(new_options);
      setQuery(value);
    }
    else {
      var new_pens = convertPens(pens);
      setOptions(new_pens);
    }
  }

  const clearInput = () => {
    var s = document.getElementById("search_anime");
    if (s != null) {
      setTimeout(() => { s.value = "" }, 50)
      setOptions(convertPens(pens));
    }
  }

  function onChange(e, value) {
    if (value != "" && value != null) {
      setPen(value);
      setOpen(true);
    }
  }

  function handleLang(lang) {
    setLang(lang);
    handlePens();
  }



  function PenDescription(props) {
    const handleOpen = () => props.setOpen(true);
    const handleClose = () => props.setOpen(false);
    var pen = props.pen;
    var style = modalStyle;
    if (is_mobile)
      style = mobileModalStyle;
    return (
      <div>
        <Modal
          id="pen-description"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{ overflow: 'scroll' }}
        >
          <PenContent pen={pen} open={true} style={style}></PenContent>
        </Modal>
      </div>
    );
  }

  
  function PenContent(props) {
    var domain = window.location.hostname;
    domain = domain.replaceAll('/','')
    console.log(domain);
    var pen = props.pen;
    var pen_id = "";
    if (pen.name != null)
      pen_id = pen.name.replaceAll('?', '').replaceAll('&', '');
    var color = '#000'
    if (pen.is_fav)
      color = '#003399'
    var width = 300;
    var img_col = 4;
    var text_col = 8;
    if (is_mobile) {
      img_col = 0;
      text_col = 12;
    }
    var desc = pen.description.find(x => x.lang == lang);
    if (desc != null)
      pen.showDescription = desc.text;
    var src = handleImageSrc(pen.name);
    document.title = pen.name;
    return (
      <Container sx={{
        display: "flex",
        justifyContent: "center"
      }}><Fade in={props.open}>
          <Box
            sx={props.style}
          >
            <Container sx={{
              display: "flex",
              justifyContent: "center"
            }}>
              {/* {is_mobile ? <img src={src} alt={pen.name} style={{ maxWidth: '100%', height: 'auto', textAlign: "center" }}></img> : <></>} */}
            </Container>
            <Grid container spacing={2}>
              {/* <Grid item xs={img_col}>
                {!is_mobile ? <img src={src} alt={pen.name} style={{ maxWidth: '100%', height: 'auto' }}></img> : <></>}
              </Grid> */}
              <Grid 
              item 
              // xs={text_col}
              >
                <div style={{ padding: 10, borderBottom: "0.5px solid #bbb", textAlign: 'center' }}>

                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    <i style={{ fontFamily: 'Hahmlet', color: color }}>{pen.name}</i>
                  </Typography>
                  <Typography sx={{ fontSize: 10 }}>
                    {pen.japanese_name}
                  </Typography>

                </div>
                <Typography id="transition-modal-description" sx={{ fontFamily: 'Spectral', mt: 2, textAlign: 'justify', textJustify: 'inter-word' }}>
                  {pen.showDescription}
                </Typography>
                <hr style={{ borderTop: "0.5px solid #bbb", marginTop: 15 }} />
                <div style={{ marginTop: 20 }}>
                  <a href="https://www.facebook.com/sharer/sharer.php?u=https://animeguess.com" target="_blank" rel="noopener">
                    <img src="/facebook.png" width="70px" height="22px" alt="Share on Facebook"></img>
                  </a>
                  <a style={{ marginLeft: 10 }} href="https://twitter.com/share?url=https://animeguess.com" target="_blank" rel="noopener">
                    <img src="/twitter.png" width="65px" height="22px" alt="Share on Twitter"></img>
                  </a>
                  <a href={'https://' + domain + '/index.html?sub=disqus&id=' + pen_id} target="_blank" rel="noopener noreferrer" >

                  {/* <a style={{ marginLeft: 10 }} href={'/disqus?id=' + pen_id} target="_blank" rel="noopener"> */}
                    <img style={{ marginLeft: 10 }} src="/comment.png" width="65px" height="22px" alt="Disqus comment"></img>
                  {/* </a> */}
                  </a>
                  <img alt="close" style={{ position: 'absolute', right: 5 }} onClick={() => setOpen(false)} src="/close.png" width="65px" height="22px" ></img>
                </div>
                {/* <Typography id="transition-modal-note" sx={{ position: "absolute", left: 10, bottom: 10, fontSize: 10 }}>
            『Description is only accurate when {pen.name} is your most favorite anime <b>of all time</b>!』
            </Typography> */}
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Container>)
  }



  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
      <link href="https://fonts.googleapis.com/css2?family=Hahmlet:wght@600&family=Spectral:wght@300&display=swap" rel="stylesheet"></link>


      <Container>
        <div style={{ textAlign: "center", marginTop: 60 }}>
           <div>Pick your <b>most favorite anime of all time...</b> and I'll guess your personality!</div>
        </div>
        <div style={{ textAlign: 'center', marginTop: 40, marginBottom: 40 }}>『Description is only accurate when it is your most favorite anime <b>of all time</b>!』</div>


        {/* <TextField value={query} onChange={(e) => onSearch(e.target.value)} style={{ marginTop: 20 }} size="small" label={<SearchIcon />} id="anime-name" variant="outlined" /> */}
      </Container>
      <Container sx={{ display: "flex", justifyContent: "center" }}>

        <Autocomplete
          id="search_anime"
          size="small"
          options={options}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.name}
          sx={{ width: "50vw", maxWidth: 550 }}
          blurOnSelect={true}
          onFocus={clearInput}
          renderInput={(params) => <TextField {...params} label={<SearchIcon />} />}
          onInputChange={onInputChange}
          onChange={onChange}
        />
      </Container>
      {/* <ul marginTop={10}>
        <FlatList
          list={gridPens}
          renderItem={renderSquare}
          renderWhenEmpty={() => <div>The author's imagination was unable to cover this mysterious area. Maybe you can check back later?</div>}
        // sortBy={["firstName"]}
        />
      </ul> */}

      <PenDescription open={openState} pen={currentPen} setOpen={setOpen}></PenDescription>
    </>

  );
}

export default Main;
