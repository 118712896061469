//remove : and /, ? before using images
export const pens = [
    //Isekai

    {
        name: "Re: Zero - Starting Life in Another World",
        japanese_name: "Re:ゼロから始める異世界生活",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "Although you're basically a good person and you have no problem understanding complex things, you are not very strong emotionally and you have a too convoluted way of thinking about relationships and the world, that's why you often miss good opportunities in life and overthink things. You often think too much about what would have happened if you did some things differently, although you can't go to the past and change them.",
                jobs: "Novelist, Psychologist, Programmer "
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは基本的に良い人であり、複雑なことを理解するのに問題はありませんが、感情的にあまり強くなく、人間関係や世界についてあまりにも複雑な考え方をしているため、人生の良い機会を逃して物事を考えすぎることがよくあります 。 過去に行って変更することはできませんが、何か別のことをした場合に何が起こったのかを考えすぎることがよくあります。"
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "Although you're basically a good person and you have no problem understanding complex things, you are not very strong emotionally and you have a too convoluted way of thinking about relationships and the world, that's why you often miss good opportunities in life and overthink things. You often think too much about what would have happened if you did some things differently, although you can't go to the past and change them.",
                jobs: "Novelist, Psychologist, Programmer "
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは基本的に良い人であり、複雑なことを理解するのに問題はありませんが、感情的にあまり強くなく、人間関係や世界についてあまりにも複雑な考え方をしているため、人生の良い機会を逃して物事を考えすぎることがよくあります 。 過去に行って変更することはできませんが、何か別のことをした場合に何が起こったのかを考えすぎることがよくあります。"
            }
        ]
    },
    {
        name: "Kono Subarashii Sekai ni Shukufuku wo!",
        japanese_name: "この素晴らしい世界に祝福を!",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You are a fun-loving person and you can't stand boredom. Your best strength is your ability to ignore bad things in the world and just enjoy little things in life. You believe that too serious and too much thinking is a waste of time since everyone will die anyway.",
                jobs: "Kid Teacher, Candy Maker, Circus Artist"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは楽しい人であり、退屈に耐えることはできません。 あなたの最大の強みは、世界の悪いことを無視し、人生の小さなことを楽しむ能力です。 とにかく誰もが死ぬので、真面目すぎて考えすぎは時間の無駄だとあなたは信じています。"
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a fun-loving person and you can't stand boredom. Your best strength is your ability to ignore bad things in the world and just enjoy little things in life. That makes it difficult for you to reach true new heights and serious relationships. You believe that too serious and too much thinking is a waste of time since everyone will die anyway.",
                jobs: "Kid Teacher, Candy Maker, Circus Artist"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは楽しい人であり、退屈に耐えることはできません。 あなたの最大の強みは、世界の悪いことを無視し、人生の小さなことを楽しむ能力です。 それはあなたが真の新しい高さと深刻な関係に到達することを困難にします。 とにかく誰もが死ぬので、真面目すぎて考えすぎは時間の無駄だとあなたは信じています。"
            }
        ]
    },
    {
        name: "Tensei Shitara Slime datta ken!",
        japanese_name: "転生したらスライムだった件",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "Although you're not very strong-minded, you are basically a good person and you have the intelligence to just evade bad things and reach good things in life. You are very flexible and have a good façade though and you're able to be whatever people like you to be.",
                jobs: "Programmer, Teacher, Translator"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたはあまり強い心を持っていませんが、基本的には良い人であり、人生で悪いことを避けて良いことに到達するための知性を持っています。 あなたは非常に柔軟性があり、良い外観を持っていますが、あなたはあなたのような人になることができます。"
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "Although you're not very strong-minded, you are basically a good person and you have the intelligence to just evade bad things and reach good things in life. You can pretty much learn anything you like and be good at it, but you're not as excellent as you would like to be. You are very flexible and have a good façade though and you're able to be whatever people like you to be. Freedom is everything. Sometimes people think you're a little bland though. At the end of the day you wonder who you really are?",
                jobs: "Programmer, Teacher, Translator"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたはあまり強い心を持っていませんが、基本的には良い人であり、人生で悪いことを避けて良いことに到達するための知性を持っています。 あなたはあなたが好きなことをほとんど学び、それを上手に行うことができますが、あなたはあなたが望むほど優秀ではありません。 あなたは非常に柔軟性があり、良い外観を持っていますが、あなたはあなたのような人になることができます。 自由がすべてです。 時々人々はあなたが少し当たり障りのない人だと思います。 一日の終わりに、あなたはあなたが本当に誰であるか疑問に思いますか？"
            }
        ]
    },
    {
        name: "Mushoku Tensei: Isekai Ittara Honki Dasu - Jobless Reincarnation",
        japanese_name: "無職転生 〜異世界行ったら本気だす〜",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You are a quite casual person and it's easy for you to make friends. People think about you as a simple person with simple needs, but you're actually more perceptive than you look. You're basically quite flexible and you don't believe that there is a set standard of morals, it's always dependent on the situation.",
                jobs: "Salesman, Programmer, Translator, Lawyer"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたはとてもカジュアルな人で、友達を作るのは簡単です。 人々はあなたを単純なニーズを持つ単純な人だと考えていますが、実際にはあなたは見た目よりも知覚力があります。 あなたは基本的に非常に柔軟性があり、道徳の基準が設定されているとは信じていません。それは常に状況に依存しています。"
            }
        ],
        showDescription: "You are a quite casual person and it's easy for you to make friends. People think about you as a simple person with simple needs, but you're actually more perceptive than you look. You're basically quite flexible and you don't believe that there is a set standard of morals, it's always dependent on the situation.",
        original_description: [
            {
                lang: "en",
                text: "You are a quite casual person and it's easy for you to make friends. People think about you as a simple person with simple needs, but you're actually more perceptive than you look. Your problem is that you're a Jack of all trades, master of none. You can pretty much learn anything you like and be good at it, but you're not as excellent as you would want to be. You're basically quite flexible and you don't believe that there is a set standard of morals, it's always dependent on the situation. Sometimes girls hate you because of this.",
                jobs: "Salesman, Programmer, Translator, Lawyer"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたはとてもカジュアルな人で、友達を作るのは簡単です。 人々はあなたを単純なニーズを持つ単純な人だと考えていますが、実際にはあなたは見た目よりも知覚力があります。 あなたの問題は、あなたがすべての取引のジャックであり、誰のマスターでもないということです。 あなたはあなたが好きなことをほとんど学び、それを上手に行うことができますが、あなたはあなたが望むほど優秀ではありません。 あなたは基本的に非常に柔軟性があり、道徳の基準が設定されているとは信じていません。それは常に状況に依存しています。 時々女の子はこれのためにあなたを嫌います。"
            }
        ]
    },
    {
        name: "No Game No Life",
        japanese_name: "ノーゲーム・ノーライフ",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "As a kid, you were one of the brightest ones in class. You got everything from that, and believe you will always be a winner if you keep being smart, and success is basically dependent on intelligence. You are extremely proud of your abilities.",
                jobs: "Scientist, Mad Scientist, Programmer"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "子供の頃、あなたはクラスで最も明るい人の1人でした。 あなたはそれからすべてを手に入れました、そしてあなたが賢くなり続ければあなたは常に勝者になると信じています、そして成功は基本的に知性に依存しています。 あなたは自分の能力を非常に誇りに思っています。"
            }
        ],
        showDescription: "As a kid, you were one of the brightest ones in class. You got everything from that, and believe you will always be a winner if you keep being smart, and success is basically dependent on intelligence. You are extremely proud of your abilities.",
        original_description: [
            {
                lang: "en",
                text: "As a kid, you were one of the brightest ones in class. You got everything from that, and believe you will always be a winner if you keep being smart, and success is basically dependent on intelligence. You are extremely proud of your abilities. The reality hit you hard and now you believe you can solve most problems with intelligence, but not all. You are socially awkward and it's hard for you to trust anyone unless they're as smart as you or smarter than you.",
                jobs: "Scientist, Mad Scientist, Programmer"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "子供の頃、あなたはクラスで最も明るい人の1人でした。 あなたはそれからすべてを手に入れました、そしてあなたが賢くなり続ければあなたは常に勝者になると信じています、そして成功は基本的に知性に依存しています。 あなたは自分の能力を非常に誇りに思っています。 現実はあなたに大きな打撃を与えました、そして今あなたはあなたが知性でほとんどの問題を解決できると信じていますが、すべてではありません。 あなたは社会的にぎこちなく、あなたと同じくらい頭が良いか、あなたより頭が良い人でない限り、誰かを信頼するのは難しいです。"
            }
        ]
    },
    {
        name: "Kumo Desu ga, Nanika? - So I'm a Spider, So What?",
        japanese_name: "蜘蛛ですが、なにか？",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You are a very casual person and it's easy for you to make friends. You are the type that can smile in any situation. That doesn't mean you are strong, it's just that you don't consider a lot of things to be important enough for you to be sad about them. You are actually quite delicate, and you hate verbal abuse. You prefer thinking about ways to solve problems to worrying about it."
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたはとてもカジュアルな人で、友達を作るのは簡単です。 あなたはどんな状況でも笑顔ができるタイプです。 それはあなたが強いという意味ではありません、それはあなたがそれらについて悲しむのに十分重要であるとあなたが多くのことを考えていないということだけです。 あなたは実際には非常にデリケートであり、言葉による虐待を嫌います。 あなたはそれを心配するよりも問題を解決する方法を考えることを好みます。"
            }
        ],
        showDescription: "You are a very casual person and it's easy for you to make friends. You are the type that can smile in any situation. That doesn't mean you are strong, it's just that you don't consider a lot of things to be important enough for you to be sad about them. You are actually quite delicate, and you hate verbal abuse. You prefer thinking about ways to solve problems to worrying about it.",
        original_description: [
            {
                lang: "en",
                text: "You are a very casual person and it's easy for you to make friends. You are the type that can smile in any situation. That doesn't mean you are strong, it's just that you don't consider a lot of things to be important enough for you to be sad about them. You are actually quite delicate, and you hate verbal abuse. You prefer thinking about ways to solve problems to worrying about it. You're a kid at heart, so it's hard for you to join the world of adults although you can be more perceptive than many adults."
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたはとてもカジュアルな人で、友達を作るのは簡単です。 あなたはどんな状況でも笑顔ができるタイプです。 それはあなたが強いという意味ではありません、それはあなたがそれらについて悲しむのに十分重要であるとあなたが多くのことを考えていないということだけです。 あなたは実際には非常にデリケートであり、言葉による虐待を嫌います。 あなたはそれを心配するよりも問題を解決する方法を考えることを好みます。"
            }
        ]
    },
    {
        name: "I've Been Killing Slimes for 300 Years and Maxed Out My Level",
        japanese_name: "スライム倒して300年、知らないうちにレベルMAXになってました",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You are a casual person and it's easy for you to talk about any topic on the world. However, it's hard for you to get a best friend. People think you're quite open, but you are actually shy and reserved."
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたはカジュアルな人であり、世界のあらゆるトピックについて話すのは簡単です。 しかし、あなたが親友を得るのは難しいです。 人々はあなたがかなりオープンだと思っていますが、あなたは実際には恥ずかしがり屋で控えめです。"
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a casual person and it's easy for you to talk about any topic on the world. However, it's hard for you to get a best friend. People think you're quite open, but you are actually shy and reserved. You like a normal, stable life with no big event, and you have no ambitions. You like to have some fun now and then, but you run away from risks. You are very positive and believe everything will be fine in the end and you don't have to think too much about solutions because you're too lazy. You're also a kid at heart, and it's hard for you to join the world of adults."
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたはカジュアルな人であり、世界のあらゆるトピックについて話すのは簡単です。 しかし、あなたが親友を得るのは難しいです。 人々はあなたがかなりオープンだと思っていますが、あなたは実際には恥ずかしがり屋で控えめです。 あなたは大きな出来事のない普通の安定した生活が好きで、野心もありません。 あなたは時々楽しみたいのですが、リスクから逃げ出します。 あなたは非常に前向きで、最終的にはすべてがうまくいくと信じています。怠惰すぎるので、解決策についてあまり考える必要はありません。 あなたも心の子供であり、大人の世界に参加するのは難しいです"
            }
        ]
    },
    {
        name: "Hunter X Hunter",
        japanese_name: "ハンター×ハンター",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You're basically a good and nice person, but your own character is very different from other people. You are super independent intellectually and psychologically and you often follow your own thoughts while everyone is always having common thoughts although you usually try to pretend to have the common thoughts, and you're proud of that. You have the mindset of a hunter, and you overanalyze most things. While normal people like to have a normal life with normal things, you actually like a turbulent life with unpredictable things. You are able to fully understand the psychology of both good and evil people. ",
                jobs: "Hunter, Programmer, Archeologist, Scientist, Novelist"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは基本的に善良な人ですが、あなた自身の性格は他の人とは大きく異なります。 あなたは知的および心理的に非常に独立しており、通常は共通の考えを持っているふりをしようとしますが、誰もが常に共通の考えを持っている間、あなたはしばしば自分の考えに従います、そしてあなたはそれを誇りに思っています。 あなたはハンターの考え方を持っており、ほとんどのものを分析しすぎています。 普通の人は普通のことで普通の生活を送るのが好きですが、あなたは実際には予測できないもので乱れた生活をするのが好きです。 あなたは善人と悪人の両方の心理学を完全に理解することができます。"
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You're basically a good and nice person, but your own character is very different from other people. You are super independent intellectually and psychologically and you often follow your own thoughts while everyone is always having common thoughts although you usually try to pretend to have the common thoughts, and you're proud of that. You have the mindset of a hunter, and you overanalyze most things. While normal people like to have a normal life with normal things, you actually like a turbulent life with unpredictable things. You are able to fully understand the psychology of both good and evil people. Although your intelligence is higher than most people, you often have a problem with the society and your development because you're inherently different from other people and sometimes it shows when your facade is not up. ",
                jobs: "Hunter, Programmer, Archeologist, Scientist, Novelist"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは基本的に善良な人ですが、あなた自身の性格は他の人とは大きく異なります。 あなたは知的および心理的に非常に独立しており、通常は共通の考えを持っているふりをしようとしますが、誰もが常に共通の考えを持っている間、あなたはしばしば自分の考えに従います、そしてあなたはそれを誇りに思っています。 あなたはハンターの考え方を持っており、ほとんどのものを分析しすぎています。 普通の人は普通のことで普通の生活を送るのが好きですが、あなたは実際には予測できないもので乱れた生活をするのが好きです。 あなたは善人と悪人の両方の心理学を完全に理解することができます。 あなたの知性はほとんどの人よりも高いですが、あなたは他の人と本質的に異なっており、時にはあなたのファサードが上がっていないときにそれが現れるので、あなたはしばしば社会とあなたの発達に問題を抱えています。"
            }
        ]
    },
    {
        name: "Yu Yu Hakusho",
        japanese_name: "幽☆遊☆白書",
        description: [
            {
                lang: "en",
                text: "You believe that the end justify the means, and an evil tool can be used to achieve good goals. You are able to fully understand the psychology of both good and evil people. You think that anger can actually be used to achieve goals as long as it is shown at the right time and the right place, and rough measures are necessary to solve some problems. You love the winning feeling, and sometimes you suffer because you do everything you can to win."
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは終わりが手段を正当化すると信じています、そして邪悪な道具は良い目標を達成するために使われることができます。 あなたは善人と悪人の両方の心理学を完全に理解することができます。 怒りは、適切なタイミングと場所で示されれば、実際に目標を達成するために使用できると思います。いくつかの問題を解決するには、大まかな対策が必要です。 あなたは勝利の気持ちが大好きです、そしてあなたが勝つためにあなたができるすべてをするので時々あなたは苦しみます。"
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You believe that the end justify the means, and an evil tool can be used to achieve good goals. You are able to fully understand the psychology of both good and evil people. You think that anger can actually be used to achieve goals as long as it is shown at the right time and the right place, and rough measures are necessary to solve some problems. You love the winning feeling, and sometimes you suffer because you do everything you can to win."
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは終わりが手段を正当化すると信じています、そして邪悪な道具は良い目標を達成するために使われることができます。 あなたは善人と悪人の両方の心理学を完全に理解することができます。 怒りは、適切なタイミングと場所で示されれば、実際に目標を達成するために使用できると思います。いくつかの問題を解決するには、大まかな対策が必要です。 あなたは勝利の気持ちが大好きです、そしてあなたが勝つためにあなたができるすべてをするので時々あなたは苦しみます。"
            }
        ]
    },
    {
        name: "Mushishi",
        japanese_name: "蟲師",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You are basically like a ghost. You do not fear death and you are not afraid of being non-existent. You feel that you can accept anything in life though. You are also not afraid of the dark and you can walk at night without lights. You think great things are made from small parts with lots of efforts over a long time. You like to study about life and death itself.",
                jobs: "Programmer, Tomb Guard, Scientist, Novelist"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは基本的に幽霊のようです。 あなたは死を恐れず、存在しないことを恐れません。 しかし、あなたは人生で何でも受け入れることができると感じています。 あなたはまた、暗闇を恐れず、夜は明かりなしで歩くことができます。 あなたは素晴らしいものが長い間多くの努力を払って小さな部品から作られていると思います。 あなたは生と死そのものについて勉強するのが好きです。"
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are basically like a ghost. You do not fear death and you are not afraid of being non-existent. Sometimes you feel that you don't care about anything. You feel that you can accept anything in life though. You are also not afraid of the dark and you can walk at night without lights. You think great things are made from small parts with lots of efforts over a long time. You like to study about life and death itself. Although you are a gentle person, your lover doesn't feel enough warmth from you so it's easy for your lover to leave you.",
                jobs: "Programmer, Tomb Guard, Scientist, Novelist"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは基本的に幽霊のようです。 あなたは死を恐れず、存在しないことを恐れません。 何も気にしないと感じることがあります。 しかし、あなたは人生で何でも受け入れることができると感じています。 あなたはまた、暗闇を恐れず、夜は明かりなしで歩くことができます。 あなたは素晴らしいものが長い間多くの努力を払って小さな部品から作られていると思います。 あなたは生と死そのものについて勉強するのが好きです。 あなたは優しい人ですが、恋人はあなたから十分な暖かさを感じていないので、あなたの恋人はあなたを離れるのは簡単です。"
            }
        ]
    },
    {
        name: "Demon Slayer: Kimetsu no Yaiba",
        japanese_name: "鬼滅の刃",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You're very patient and hardworking. You're quite sweet and gentle although you don't really know how to say sweet words. You're practical, but you never make compromises when it comes to principles and morals. You believe that pain makes us stronger and it's not possible to go on in life without pain. You're also sentimental and romantic. Your problem is that you have many contradicting sides. Sometimes you cannot decide which direction to go, because you have strong tendencies towards contradicting things.",
                jobs: "Psychologist, Writer"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたはとても辛抱強く勤勉です。 甘い言葉の言い方がよくわからないけど、とても優しくて優しいです。 あなたは実用的ですが、原則と道徳に関しては決して妥協することはありません。 あなたは痛みが私たちを強くし、痛みなしで人生を続けることは不可能であると信じています。 あなたも感傷的でロマンチックです。 あなたの問題はあなたが多くの相反する側面を持っているということです。 矛盾する傾向が強いため、どちらの方向に進むかを決めることができない場合があります。"
            }
        ]
    },
    {
        name: "Nisekoi",
        japanese_name: "ニセコイ",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You are a sweet and gentle person. Your best strength is your ability to ignore bad things in the world and just enjoy little things in life. That makes it difficult for you to reach true new heights and serious relationships, although you really want to reach them. You're a child at heart, and it's hard for you to join the world of adults.",
                jobs: "Psychologist, Writer"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは優しくて優しい人です。 あなたの最大の強みは、世界の悪いことを無視し、人生の小さなことを楽しむ能力です。 それはあなたが本当にそれらに到達したいのに、あなたが真の新しい高さと深刻な関係に到達することを困難にします。 あなたは本質的に子供であり、大人の世界に参加するのは難しいです。"
            }
        ],
    },
    {
        name: "Wandering Witch: The Journey of Elaina",
        japanese_name: "魔女の旅々",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You have the tendency to love persons who do not exist. You have limitless imagination, you can imagine yourself in any scenario, including doing things with characters. You are a little childlike, although your worldview is quite mature and objective. You are always positive and hope for the best, but you don't care about others enough to be pushy and help too much in others' business, including your lover, family and friends. You love freedom, and no one can take your freedom away. You have a secret wish to be a cute girl and enjoy a cute life with your best girlfriends. And have fantasy vibes around your life on top of that. You know a lot of things, and you can talk about any topic with ease, your friends like you because of that.",
                jobs: "Psychologist, Philosopher, Travelling Blogger"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは存在しない人を愛する傾向があります。 あなたには無限の想像力があり、キャラクターを使って物事を行うことを含め、あらゆるシナリオで自分自身を想像することができます。 あなたの世界観はかなり成熟していて客観的ですが、あなたは少し子供っぽいです。 あなたは常に前向きで、最高のものを望んでいますが、あなたは他人を気にせず、恋人、家族、友人など、他人のビジネスを強引に助けすぎます。 あなたは自由を愛し、誰もあなたの自由を奪うことはできません。 あなたはかわいい女の子になりたいという秘密の願いを持っており、あなたの最高のガールフレンドと一緒にかわいい生活を楽しんでいます。 そして、その上にあなたの人生の周りにファンタジーの雰囲気を持っています。 あなたはたくさんのことを知っています、そしてあなたはどんなトピックについても簡単に話すことができます、そのためあなたのようなあなたの友人。"
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You have the tendency to love persons who do not exist. You have limitless imagination, you can imagine yourself in any scenario, including doing things with characters. You have a problem because your dream just cannot come true, you cannot be with the person you love no matter what you do. You are a little childlike, although your worldview is quite mature and objective. You are always positive and hope for the best, but you don't care about others enough to be pushy and help too much in others' business, including your lover, family and friends. You love freedom, and no one can take your freedom away. You have a secret wish to be a cute girl and enjoy a cute life with your best girlfriends. And have fantasy vibes around your life on top of that. You have problems with your life because you want it to be exciting and stable at the same time, and that's impossible most of the time. You can pretty much learn anything you like and be good at it, but you're not as excellent as you would like to be. You know a lot of things, and you can talk about any topic with ease, your friends like you because of that.",
                jobs: "Psychologist, Philosopher, Travelling Blogger"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは存在しない人を愛する傾向があります。あなたには無限の想像力があり、キャラクターを使って物事を行うことを含め、あらゆるシナリオで自分自身を想像することができます。あなたの夢は実現できないので、あなたは問題を抱えています。あなたが何をしても、あなたは愛する人と一緒にいることはできません。あなたの世界観はかなり成熟していて客観的ですが、あなたは少し子供っぽいです。あなたは常に前向きで、最高のものを望んでいますが、あなたは他人を気にせず、恋人、家族、友人など、他人のビジネスを強引に助けすぎます。あなたは自由を愛し、誰もあなたの自由を奪うことはできません。あなたはかわいい女の子になりたいという秘密の願いを持っており、あなたの最高のガールフレンドと一緒にかわいい生活を楽しんでいます。そして、その上にあなたの人生の周りにファンタジーの雰囲気を持っています。人生をエキサイティングで安定させたいので、人生に問題がありますが、それはほとんどの場合不可能です。あなたはあなたが好きなことをほとんど学び、それを上手に行うことができますが、あなたはあなたが望むほど優秀ではありません。あなたはたくさんのことを知っています、そしてあなたはどんなトピックについても簡単に話すことができます、そのためあなたのようなあなたの友人。"
            }
        ]
    },
    {
        name: "Bakemonogatari",
        japanese_name: "化物語",
        description: [
            {
                lang: "en",
                text: "You are basically a weird person who have deep thoughts which distance yourself from others. You have always liked symbols like Hanzi or geometric shapes, and believe they have mysterious powers. You like to play feeling games with friends and romantic interests, that means they say mysterious things with vague ideas and meanings together with you, each of you have a story and an interpretation of your own as of whether they are true or not. You believe that the world is basically not real, and important things depend a lot on each person's interpretation of them. You're passionate about human psychology, and you have a hobby of watching people.",
                jobs: "Psychologist, Writer, Lawyer"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは基本的に、他人から自分を遠ざける深い考えを持っている奇妙な人です。 あなたはいつも漢字や幾何学的な形のようなシンボルが好きで、それらが神秘的な力を持っていると信じています。 あなたは友達やロマンチックな興味を持って気持ちの良いゲームをするのが好きです。つまり、彼らはあなたと一緒に漠然とした考えや意味で不思議なことを言います。 あなたは世界は基本的に現実ではないと信じています、そして重要なことはそれらの一人一人の解釈に大きく依存します。 あなたは人間の心理学に情熱を持っており、人々を見るのが趣味です。"
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You are basically a weird person who have deep thoughts which distance yourself from others. You have always liked symbols like Hanzi or geometric shapes, and believe they have mysterious powers. You like to play feeling games with friends and romantic interests, that means they say mysterious things with vague ideas and meanings together with you, each of you have a story and an interpretation of your own as of whether they are true or not. You believe that the world is basically not real, and important things depend a lot on each person's interpretation of them. Although you're basically a good person, you have a lot of ideas to do mischievous things for good goals. You also think that sinful things are quite stimulating. You're passionate about human psychology, and you have a hobby of watching people.",
                jobs: "Psychologist, Writer, Lawyer"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは基本的に、他人から自分を遠ざける深い考えを持っている奇妙な人です。 あなたはいつも漢字や幾何学的な形のようなシンボルが好きで、それらが神秘的な力を持っていると信じています。 あなたは友達やロマンチックな興味を持って気持ちの良いゲームをするのが好きです。つまり、彼らはあなたと一緒に漠然とした考えや意味で不思議なことを言います。 あなたは世界は基本的に現実ではないと信じています、そして重要なことはそれらの一人一人の解釈に大きく依存します。 あなたは基本的に良い人ですが、良い目標のためにいたずらをするためのアイデアはたくさんあります。 また、罪深いことは非常に刺激的だと思います。 あなたは人間の心理学に情熱を持っており、人々を見るのが趣味です。"
            }
        ]
    },
    //Fav
    {
        name: "To Love Ru",
        japanese_name: "To LOVEる -とらぶる-",
        description: [
            {
                lang: "en",
                text: "You know a lot of things, and you can talk about any topic with ease, your friends like you because of that. You have limitless imagination, and you're a real nerd. You can get into trouble because you say something no one can imagine that you would say. Your lover may be dissatified with you because you're not \"rough\" enough when you need to be \"rough\", but your lover still likes you because you are \"gentle\". You can't stand boredom and repetitive tasks, and an interesting job is better than a well-paid job."
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたはたくさんのことを知っています、そしてあなたは簡単にどんなトピックについても話すことができます、そのためあなたのようなあなたの友人。あなたは無限の想像力を持っています、そしてあなたは本当のオタクです。あなたは誰も言わないのであなたはトラブルに巻き込まれる可能性があります あなたが言うことを想像することができます。あなたが「ラフ」である必要があるときにあなたが「ラフ」ではないためにあなたの恋人はあなたに不満を抱くかもしれませんが、あなたは「優しい」のであなたの恋人はまだあなたを好きです 。退屈で反復的な仕事に耐えることはできません。そして、面白い仕事は高給の仕事よりも優れています。"
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You know a lot of things, and you can talk about any topic with ease, your friends like you because of that. You have limitless imagination, and you're a real nerd. You can get into trouble because you say something no one can imagine that you would say. Your lover may be dissatified with you because you're not \"rough\" enough when you need to be \"rough\", but your lover still likes you because you are \"gentle\". You like polyamory and you'd like to try it someday. You can't stand boredom and repetitive tasks, and an interesting job is better than a well-paid job. Your problem is that you have a lot of things you'd like to do, but you tend to do those things half-assedly and stop working on those when those are still not done."
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたはたくさんのことを知っています、そしてあなたはどんなトピックについても簡単に話すことができます、そのためあなたのようなあなたの友人。 あなたには無限の想像力があり、あなたは本当のオタクです。 誰もあなたが言うとは想像できないことを言うので、あなたはトラブルに巻き込まれる可能性があります。 あなたが「ラフ」である必要があるときにあなたが「ラフ」ではないためにあなたの恋人はあなたに不満を抱くかもしれませんが、あなたは「優しい」のであなたの恋人はまだあなたを好きです。 あなたはポリアモリーが好きで、いつかそれを試してみたいと思っています。 あなたは退屈で反復的な仕事に耐えることができません、そして面白い仕事は高給の仕事よりも優れています。 あなたの問題はあなたがやりたいことがたくさんあるということです、しかしあなたはそれらのことを中途半端に行い、それらがまだ行われていないときにそれらに取り組むのをやめる傾向があります。"
            }
        ]
    },
    {
        name: "Ayakashi Triangle",
        japanese_name: "あやかしトライアングル",
        description: [
            {
                lang: "en",
                text: "You have a secret wish to be a normal high-school girl and enjoy a cute life with your best girlfriends. And have fantasy vibes around your life on top of that. People love you because you're a good and lovely person though. It's easy for you to make online female friends. Your lover may be dissatified with you because you're not \"rough\" enough when you need to be \"rough\", but your lover still likes you because you are \"gentle\". You can't stand boredom and repetitive tasks, and an interesting job is better than a well-paid job."
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは普通の女子高生になりたいという秘密の願いを持っていて、あなたの最高のガールフレンドと一緒にかわいい人生を楽しんでいます。 そして、その上にあなたの人生の周りにファンタジーの雰囲気を持っています。 でも、あなたは善良で素敵な人なので、人々はあなたを愛しています。 オンラインで女性の友達を作るのは簡単です。 あなたが「ラフ」である必要があるときにあなたが「ラフ」ではないためにあなたの恋人はあなたに不満を抱くかもしれませんが、あなたは「優しい」のであなたの恋人はまだあなたを好きです。 あなたは退屈で反復的な仕事に耐えることができません、そして面白い仕事は高給の仕事よりも優れています。"
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You have a secret wish to be a normal high-school girl and enjoy a cute life with your best girlfriends. And have fantasy vibes around your life on top of that. You have problems with your life because you want it to be exciting and stable at the same time. You do realize that there is no such thing, but you still look for it all your life. People love you because you're a good and lovely person though. It's easy for you to make online female friends. Your lover may be dissatified with you because you're not \"rough\" enough when you need to be \"rough\", but your lover still likes you because you are \"gentle\". You can't stand boredom and repetitive tasks, and an interesting job is better than a well-paid job. Your problem is that you have a lot of things you'd like to do, but you tend to do those things half-assedly and stop working on those when those are still not done."
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは普通の女子高生になりたいという秘密の願いを持っていて、あなたの最高のガールフレンドと一緒にかわいい人生を楽しんでいます。 そして、その上にあなたの人生の周りにファンタジーの雰囲気を持っています。 エキサイティングで安定した人生を送りたいので、人生に問題があります。 あなたはそのようなものがないことに気づきます、しかしあなたはそれでもあなたの人生を通してそれを探します。 でも、あなたは善良で素敵な人なので、人々はあなたを愛しています。 オンラインで女性の友達を作るのは簡単です。 あなたが「ラフ」である必要があるときにあなたが「ラフ」ではないためにあなたの恋人はあなたに不満を抱くかもしれませんが、あなたは「優しい」のであなたの恋人はまだあなたを好きです。 あなたは退屈で反復的な仕事に耐えることができません、そして面白い仕事は高給の仕事よりも優れています。 あなたの問題はあなたがやりたいことがたくさんあるということです、しかしあなたはそれらのことを中途半端に行い、それらがまだ行われていないときにそれらに取り組むのをやめる傾向があります。"
            }
        ]
    },
    {
        name: "Date A Live",
        japanese_name: "デート・ア・ライブ",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You're basically a selfless person who is really good and nice. You easily give up your own benefits for others. You are not innocent though, and you know enough about evil to evade it. You like a simple life, and you don't feel the need to get better things than other people.",
                jobs: "Teacher"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは基本的に無私無欲な人で、本当に上手でいい人です。 あなたは他人のためにあなた自身の利益を簡単に諦めます。 しかし、あなたは無実ではありません、そしてあなたはそれを回避するのに十分な悪について知っています。 あなたはシンプルな生活が好きで、他の人よりも良いものを手に入れる必要性を感じません。"
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You're basically a selfless person who is really good and nice. You easily give up your own benefits for others. You are not innocent though, and you know enough about evil to evade it. You're a kid at heart, and it's hard for you to join the world of adults. You like a simple life, and you don't feel the need to get better things than other people. You can be stubborn and inflexible at times.",
                jobs: "Teacher"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたは基本的に無私無欲な人で、本当に上手でいい人です。 あなたは他人のためにあなた自身の利益を簡単に諦めます。 しかし、あなたは無実ではありません、そしてあなたはそれを回避するのに十分な悪について知っています。 あなたは本質的に子供であり、大人の世界に参加するのは難しいです。 あなたはシンプルな生活が好きで、他の人よりも良いものを手に入れる必要性を感じません。 あなたは時々頑固で柔軟性がないことがあります。"
            }
        ]
    },
    {
        name: "Inuyasha",
        japanese_name: "犬夜叉",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "It's basically impossible to know exactly what your feeling is when you are in a certain situation, because your personality is so complex that you don't understand all of it yourself. You are the type of person that acts on your impulse, rather than careful planning. Your lover likes you because you are never boring. You are also quite romantic and you care a lot about your friends, family and soulmate. You also got a great imagination and a surprisingly good sense of reasoning. Despite all of that, you're a quite good, nice and honest person. You can basically feel sympathetic for both good and bad people. You're quite delicate though, and you have a lot of mood swings. You also tend to get mad at random little things. So you're emotionally unstable, and that makes some people in your social circles worry a lot about you. Other people in your social circles are used to your mood swings, and know they can do nothing about it and you'll sort your feelings out yourself.",
                jobs: "Psychologist, Writer, Composer"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたの性格は非常に複雑で、すべてを自分で理解することはできないため、特定の状況にあるときの自分の気持ちを正確に知ることは基本的に不可能です。 あなたは慎重な計画ではなく、あなたの衝動に基づいて行動するタイプの人です。 あなたが退屈することは決してないので、あなたの恋人はあなたを好きです。 あなたはまた非常にロマンチックで、あなたはあなたの友人、家族、そしてソウルメイトをとても気にかけています。 あなたはまた、素晴らしい想像力と驚くほど良い推論の感覚を得ました。 それにもかかわらず、あなたはとても上手で、素晴らしく、正直な人です。 あなたは基本的に善人と悪人の両方に同情を感じることができます。 しかし、あなたは非常に繊細で、気分のむらがたくさんあります。 あなたはまた、ランダムな小さなことに腹を立てる傾向があります。 ですから、あなたは感情的に不安定であり、それはあなたの社会的サークルの何人かの人々にあなたについて多くのことを心配させます。 あなたの社会的サークルの他の人々はあなたの気分のむらに慣れていて、彼らはそれについて何もできないことを知っています、そしてあなたはあなた自身であなたの気持ちを整理します"
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "It's basically impossible to know exactly what your feeling is when you are in a certain situation, because your personality is so complex that you don't understand all of it yourself. You are the type of person that acts on your impulse, rather than careful planning. Your lover likes you because you are never boring. You are also quite romantic and you care a lot about your friends, family and soulmate. You also got a great imagination and a surprisingly good sense of reasoning. Despite all of that, you're a quite good, nice and honest person. You can basically feel sympathetic for both good and bad people. You're quite delicate though, and you have a lot of mood swings. You also tend to get mad at random little things. So you're emotionally unstable, and that makes some people in your social circles worry a lot about you. Other people in your social circles are used to your mood swings, and know they can do nothing about it and you'll sort your feelings out yourself.",
                jobs: "Psychologist, Writer, Composer"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: "あなたの性格は非常に複雑で、すべてを自分で理解することはできないため、特定の状況にあるときの自分の気持ちを正確に知ることは基本的に不可能です。 あなたは慎重な計画ではなく、あなたの衝動に基づいて行動するタイプの人です。 あなたが退屈することは決してないので、あなたの恋人はあなたを好きです。 あなたはまた非常にロマンチックで、あなたはあなたの友人、家族、そしてソウルメイトをとても気にかけています。 あなたはまた、素晴らしい想像力と驚くほど良い推論の感覚を得ました。 それにもかかわらず、あなたはとても上手で、素晴らしく、正直な人です。 あなたは基本的に善人と悪人の両方に同情を感じることができます。 しかし、あなたは非常に繊細で、気分のむらがたくさんあります。 あなたはまた、ランダムな小さなことに腹を立てる傾向があります。 ですから、あなたは感情的に不安定であり、それはあなたの社会的サークルの何人かの人々にあなたについて多くのことを心配させます。 あなたの社会的サークルの他の人々はあなたの気分のむらに慣れていて、彼らがそれについて何もできないことを知っていて、あなたはあなた自身であなたの気持ちを整理するでしょう。"
            }
        ]
    },
    {
        name: "Yashahime: Princess Half-Demon - Han'you no Yashahime",
        japanese_name: "半妖の夜叉姫",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "It's basically impossible to know exactly what your feeling is when you are in a certain situation, because your personality is so complex that you don't understand all of it yourself. You are the type of person that acts on your impulse, rather than careful planning. Your lover likes you because you are never boring. Despite all of that, you're a quite good, nice and honest person. You are also quite romantic and you care a lot about your friends, family and soulmate. You also got a great imagination and a surprisingly good sense of reasoning. You can basically feel sympathetic for both good and bad people, but you're also indecisive. You're basically quite delicate though, and you have a lot of mood swings. You also tend to get mad at random little things. So you're emotionally unstable, and that makes some people in your social circles worry a lot about you. Other people in your social circles are used to your mood swings, and know they can do nothing about it and you'll sort your feelings out yourself.",
                jobs: "Psychologist, Writer, Composer"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "It's basically impossible to know exactly what your feeling is when you are in a certain situation, because your personality is so complex that you don't understand all of it yourself. You are the type of person that acts on your impulse, rather than careful planning. Your lover likes you because you are never boring. Despite all of that, you're a quite good, nice and honest person. You are also quite romantic and you care a lot about your friends, family and soulmate. You also got a great imagination and a surprisingly good sense of reasoning. You can basically feel sympathetic for both good and bad people, but you're also indecisive. You're basically quite delicate though, and you have a lot of mood swings. You also tend to get mad at random little things. So you're emotionally unstable, and that makes some people in your social circles worry a lot about you. Other people in your social circles are used to your mood swings, and know they can do nothing about it and you'll sort your feelings out yourself.",
                jobs: "Psychologist, Writer, Composer"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Ranma ½",
        japanese_name: "らんま ½",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "It's basically impossible to know exactly what your feeling is when you are in a certain situation, because your personality is so complex that you don't understand all of it yourself. You are the type of person that acts on your impulse, rather than careful planning. Your lover likes you because you are never boring. Despite all of that, you're a quite good, nice and honest person. You are also quite romantic and sensitive and you care a lot about your friends, family and soulmate. You also got a great imagination and a surprisingly good sense of reasoning. You can basically feel sympathetic for both good and bad people. You're basically quite delicate though, and you have a lot of mood swings. You also tend to get mad at random little things. So you're emotionally unstable, and that makes some people in your social circles worry a lot about you. Other people in your social circles are used to your mood swings, and know they can do nothing about it and you'll sort your feelings out yourself. You're a kid at heart, and it's hard for you to join the world of adults. You can make friends with both boys and girls.",
                jobs: "Psychologist, Martial Artist, Kid Teacher"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "It's basically impossible to know exactly what your feeling is when you are in a certain situation, because your personality is so complex that you don't understand all of it yourself. You are the type of person that acts on your impulse, rather than careful planning. Your lover likes you because you are never boring. Despite all of that, you're a quite good, nice and honest person. You are also quite romantic and sensitive and you care a lot about your friends, family and soulmate. You also got a great imagination and a surprisingly good sense of reasoning. You can basically feel sympathetic for both good and bad people. You're basically quite delicate though, and you have a lot of mood swings. You also tend to get mad at random little things. So you're emotionally unstable, and that makes some people in your social circles worry a lot about you. Other people in your social circles are used to your mood swings, and know they can do nothing about it and you'll sort your feelings out yourself. You're a kid at heart, and it's hard for you to join the world of adults. You can make friends with both boys and girls.",
                jobs: "Psychologist, Martial Artist, Kid Teacher"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "The Ancient Magus' Bride",
        japanese_name: "魔法使いの嫁",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You are a really deep person who tends to overthink things. You're really romantic and sensitive, and you're a great lover who knows how to care and listen to other people. You have a great imagination and have always loved art and creating art. Although you're an introvert, you like travelling and learning new things. You are the type of person who enjoys little things in life slowly, and care about all the smallest details. You have no ambition and you just want a happy, peaceful life. You're very hardworking to get that kind of life. You think great things are made from small parts with lots of efforts over a long time. Sadly, it's very hard for you to achieve that, since you're not used to the cruelty of the real life. You are fully able to adapt to it, but it will take a quite long process. But you can do it. Wish you all the best.",
                jobs: "Writer, Artist, Biologist, Scientist"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a really deep person who tends to overthink things. You're really romantic and sensitive, and you're a great lover who knows how to care and listen to other people. You have a great imagination and have always loved art and creating art. Although you're an introvert, you like travelling and learning new things. You are the type of person who enjoys little things in life slowly, and care about all the smallest details. You have no ambition and you just want a happy, peaceful life. You're very hardworking to get that kind of life. You think great things are made from small parts with lots of efforts over a long time. Sadly, it's very hard for you to achieve that, since you're not used to the cruelty of the real life. You are fully able to adapt to it, but it will take a quite long process. But you can do it. Wish you all the best.",
                jobs: "Writer, Artist, Biologist, Scientist"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Your Name - Kimi no Na wa",
        japanese_name: "君の名は",
        description: [
            {
                lang: "en",
                text: "You believe that beauty lies in sadness, and sadness lies in beauty. You're a beauty seeker and you were always passionate about collecting art. You're basically a honest and delicate person, and people like you because of your extremely high attention in details. You're a clean freak, and you are obsessed in your house's cleanliness. You're a natural perfectionist designer and you try to design everything to be as beautiful, tidy and clean as possible. You think great things are made from small parts with lots of efforts over a long time. You believe in miracles, but miracles have its costs. You're basically an extremely introvert loner though, and you wish you can be as friendly and casual as everyone. You wonder why people can be really rude with their friends, but they're soft and gentle to you.",
                jobs: "Designer, Artist, Composer"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You believe that beauty lies in sadness, and sadness lies in beauty. You're a beauty seeker and you were always passionate about collecting art. You're basically a honest and delicate person, and people like you because of your extremely high attention in details. You're a clean freak, and you are obsessed in your house's cleanliness. You're a natural perfectionist designer and you try to design everything to be as beautiful, tidy and clean as possible. You think great things are made from small parts with lots of efforts over a long time. You believe in miracles, but miracles have its costs. You're basically an extremely introvert loner though, and you wish you can be as friendly and casual as everyone. You wonder why people can be really rude with their friends, but they're soft and gentle to you.",
                jobs: "Designer, Artist, Composer"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Takt Op. Destiny",
        japanese_name: "タクトオーパス",
        description: [
            {
                lang: "en",
                text: "You believe that beauty lies in sadness, and sadness lies in beauty. You're a beauty seeker and you were always passionate about creating beautiful art, or performing music with your perfectionism. You think that bad things that happen in your life are your challenges. You're basically a honest and delicate person, and people like you because of your extremely high attention in details. You're basically an extremely introvert loner though, and you wish you can be as friendly and casual as everyone. You wonder why people can be really rude with their friends, but they're soft and gentle to you.",
                jobs: "Designer, Artist, Composer"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You believe that beauty lies in sadness, and sadness lies in beauty. You're a beauty seeker and you were always passionate about creating beautiful art, or performing music with your perfectionism. You think that bad things that happen in your life are your challenges. You're basically a honest and delicate person, and people like you because of your extremely high attention in details. You're basically an extremely introvert loner though, and you wish you can be as friendly and casual as everyone. You wonder why people can be really rude with their friends, but they're soft and gentle to you.",
                jobs: "Designer, Artist, Composer"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Violet Evergarden",
        japanese_name: "ヴァイオレット・エヴァーガーデン",
        description: [
            {
                lang: "en",
                text: "You believe that beauty lies in sadness, and sadness lies in beauty. You're a beauty seeker and you were always passionate about creating beautiful art, or performing music with your perfectionism. You think great things are made from small parts with lots of efforts over a long time. You are more mature than other anime fans, and you're really serious most of the time. People think they can depend on you since you usually give honest, well-thought-out opinions. Your problem is that you devote too much time into your work, and sometimes you forget to take care of yourself.",
                jobs: "Designer, Artist, Composer"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You believe that beauty lies in sadness, and sadness lies in beauty. You're a beauty seeker and you were always passionate about creating beautiful art, or performing music with your perfectionism. You think great things are made from small parts with lots of efforts over a long time. You are more mature than other anime fans, and you're really serious most of the time. People think they can depend on you since you usually give honest, well-thought-out opinions. Your problem is that you devote too much time into your work, and sometimes you forget to take care of yourself.",
                jobs: "Designer, Artist, Composer"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Tate no Yuusha no Nariagari - The Rising of the Shield Hero",
        japanese_name: "盾の勇者の成り上がり",
        description: [
            {
                lang: "en",
                text: "You always believed that this world has a certain kind of good order, justice, fairness and most people kind of believe in that as a kid. As you grow up, you realized that it's not that simple. People think about you as a kind of strict and serious person, but you're actually very kind.",
                jobs: "Teacher"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You always believed that this world has a certain kind of good order, justice, fairness and most people kind of believe in that as a kid. As you grow up, you realized that it's not that simple. You end up giving up on most people and only believe in the ones dearest to you. You don't know much about human emotions and sometimes you say things that make people completely misunderstand your intention. People think about you as a kind of strict and serious person, but you're actually very kind.",
                jobs: "Teacher"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: ""
            }
        ]
    },
    {
        name: "Overlord",
        japanese_name: "オーバーロード",
        description: [
            {
                lang: "en",
                text: "You have no problem blending into \"normal people's space\", unlike the above anti-social circles. You're basically nice, but sometimes you are nice for a motive. You got a good balance in life and believe that too much of anything is bad including justice. You reach success in a silent and reliable way.",
                jobs: "Architect, Programmer, Manager"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You have no problem blending into \"normal people's space\", unlike the above anti-social circles. You're basically nice, but sometimes you are nice for a motive. You got a good balance in life and believe that too much of anything is bad including justice. You reach success in a silent and reliable way.",
                jobs: "Architect, Programmer, Manager"
            },
            {
                lang: "vi",
                text: ""
            },
            {
                lang: "ja",
                text: ""
            }
        ]
    },
    //Popular stuff
    {
        name: "Sword Art Online",
        japanese_name: "ソードアート・オンライン",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You are basically really aristocratic and you hate everything vulgar. You believe that valuable things should always have a certain kind of honorable air. You believe in gender equality and it's normal for boys to have girls' traits and vice versa. You are a big fan of technology and would really like a job in programming. People like you because you're honest and smart, so they often come to ask for your well-informed honest opinion."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are basically really aristocratic and you hate everything vulgar. You believe that valuable things should always have a certain kind of honorable air. You believe in gender equality and it's normal for boys to have girls' traits and vice versa. You are a big fan of technology and would really like a job in programming. People like you because you're honest and smart, so they often come to ask for your well-informed honest opinion. You are quite inflexible and slow in adapting to new situations though. You're a little weak emotionally and you often can't endure verbal abuse. You have a really strong sense of morals and sometimes you blame yourself for things that are not your fault. You don't have much self-confidence and often fail to realize your own true abilities."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Fullmetal Alchemist",
        japanese_name: "鋼の錬金術師",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You are a nice and honest person. Although you care about people's feeling, you believe that emotions can't solve problems and you often find solutions for problems and rarely complain about them. Despite your good imagination, you're basically practical and creative in your tasks. You are very consistent about things you want to do, you spend more time than others to perfect your work, and you can be quite stubborn. You're quite positive though and you always believe things will be okay in the end.",
                jobs: "Programmer, Scientist, Architect"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a nice and honest person. Although you care about people's feeling, you believe that emotions can't solve problems and you often find solutions for problems and rarely complain about them. Despite your good imagination, you're basically practical and creative in your tasks. You are very consistent about things you want to do, you spend more time than others to perfect your work, and you can be quite stubborn. You usually get upset when your goal is not completed, and you have problems in trying to find new goals and/or giving up on old ones. In other people's eyes, you are quite strong and dependable, but you actually have a delicate side that you don't like to show people. You're quite positive though and you always believe things will be okay in the end.",
                jobs: "Programmer, Scientist, Architect"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Fate/Stay Night",
        japanese_name: "フェイト・ステイナイト",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You are basically really aristocratic and you hate everything vulgar. You believe that valuable things should always have a certain kind of honorable air. Even the evils can be admirable as long as they are honorable. You think all judgements are meaningless unless they are heavily discussed with philosphy before they are made. You met many difficulties because people don't think that much about philosophy like you, and have a vulgar side. Your emotions are usually weaker than most people, but your passion is stronger than most people. You believe that it's hard to do impressive things alone, but teammates should also be really independent from each other.",
                jobs: "Lawyer, Architect, Programmer"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are basically really aristocratic and you hate everything vulgar. You believe that valuable things should always have a certain kind of honorable air. Even the evils can be admirable as long as they are honorable. You think all judgements are meaningless unless they are heavily discussed with philosphy before they are made. You met many difficulties because people don't think that much about philosophy like you, and have a vulgar side. People are often also unable to understand how you can be so calm in many situations, and you are also not able to understand many kinds of emotions from other people, particularly how they can be very petty and/or angry about very small problems. Your emotions are usually weaker than most people, but your passion is stronger than most people. You believe that it's hard to do impressive things alone, but teammates should also be really independent from each other.",
                jobs: "Lawyer, Architect, Programmer"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Fate/Grand Order",
        japanese_name: "フェイト・グランドオーダー",
        is_fav: true,
        description: [
            {
                lang: "en",
                text: "You are basically really aristocratic and you hate everything vulgar. You believe that valuable things should always have a certain kind of honorable air. Even the evils can be admirable as long as they are honorable. You think all judgements are meaningless unless they are heavily discussed with philosphy before they are made.Your emotions are usually weaker than most people, but your passion is stronger than most people. You believe that really different people with hugely different background, belief, and abilities can still co-operate and achieve great things."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are basically really aristocratic and you hate everything vulgar. You believe that valuable things should always have a certain kind of honorable air. Even the evils can be admirable as long as they are honorable. You think all judgements are meaningless unless they are heavily discussed with philosphy before they are made. You met many difficulties because people don't think that much about philosophy like you, and have a vulgar side. People are often also unable to understand how you can be so calm in many situations, and you are also not able to understand many kinds of emotions from other people, particularly how they can be very petty and/or angry about very small problems. In your job, you are not affected by emotions. Your emotions are usually weaker than most people, but your passion is stronger than most people. You believe that really different people with hugely different background, belief, and abilities can still co-operate and achieve great things."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Fate/kaleid liner Prisma Illya",
        is_fav: true,
        japanese_name: "フェイト カレイドライナー プリズマイリヤ",
        description: [
            {
                lang: "en",
                text: "You have a secret wish to be a normal high-school girl and enjoy a cute life with your best girlfriends. And have fantasy vibes around your life on top of that. People love you because you're a good and lovely person though. It's easy for you to make online female friends."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You have a secret wish to be a normal high-school girl and enjoy a cute life with your best girlfriends. And have fantasy vibes around your life on top of that. You have problems with your life because you want it to be exciting and stable at the same time. You do realize that there is no such thing, but you still look for it all your life. People love you because you're a good and lovely person though. It's easy for you to make online female friends."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Kaguya-sama wa Kokurasetai?: Tensai-tachi no Renai Zunousen",
        is_fav: true,
        japanese_name: "かぐや様は告らせたい？～天才たちの恋愛頭脳戦～",
        description: [
            {
                lang: "en",
                text: "You're a casual person, and it's easy for you to make friends of all genders. It's hard for you to have a best friend though, although you really want one, because your way of thinking about relationships is quite convoluted, that's why you're afraid of having best friends who will complicate things. You have no problem understanding complex things, but you can't sort out your own feelings. People think you're quite open, but you are actually shy and reserved. You're basically sweet, but you get embarassed when you say sweet things."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You're a casual person, and it's easy for you to make friends of all genders. It's hard for you to have a best friend though, although you really want one, because your way of thinking about relationships is quite convoluted, that's why you're afraid of having best friends who will complicate things. You have no problem understanding complex things, but you can't sort out your own feelings. People think you're quite open, but you are actually shy and reserved. You're basically sweet, but you get embarassed when you say sweet things. You like to think about yourself as a normal nice person, but you're actually a little weird."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Neon Genesis Evangelion",
        japanese_name: "新世紀エヴァンゲリオン",
        description: [
            {
                lang: "en",
                text: "You have a good memory, and you got through some periods of depression in your life. The bad memories kept haunting you, and you just want to find peace. You're quite careful and you're not afraid of repetitive tasks, and you do them reasonably well every single time."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You have a good memory, and you got through some periods of depression in your life. The bad memories kept haunting you, and you just want to find peace. You're quite careful and you're not afraid of repetitive tasks, and you do them reasonably well every single time."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Naruto",
        japanese_name: "NARUTO -ナルト-",
        description: [
            {
                lang: "en",
                text: "You are a straightforward person who likes straightforward things and media.You think the world would be much easier if it was more simple, and wish your life was a straight line from the bottom to the top. You believe that willpower is very important in life, and nothing can be solved without really determined willpower. "
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a straightforward person who likes straightforward things and media. You don't like complicated things, so you have a problem with them. You think the world would be much easier if it was more simple, and wish your life was a straight line from the bottom to the top. You believe that willpower is very important in life, and nothing can be solved without really determined willpower. "
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "BLEACH",
        japanese_name: "ブリーチ",
        description: [
            {
                lang: "en",
                text: "You are a straightforward person who likes straightforward things and media. You believe that willpower is very important in life, and nothing can be solved without really determined willpower. "
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a straightforward person who likes straightforward things and media. You don't like complicated things, so you have a problem with them. You think the world would be much easier if it was more simple, and wish your life was a straight line from the bottom to the top. You believe that willpower is very important in life, and nothing can be solved without really determined willpower. "
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "One Piece",
        japanese_name: "ワンピース",
        description: [
            {
                lang: "en",
                text: "You love freedom, freedom is everything. You have great imagination, so you use it to evade the risks in your life because you can imagine what can go wrong. You are basically practical and frank, but you can be really romantic at times. You are quite casual and it's easy for you to make friends. You think the world would be much easier if it was more simple, and wish your life was a straight line from the bottom to the top. "
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You love freedom, freedom is everything. You have great imagination, so you use it to evade the risks in your life because you can imagine what can go wrong. You are basically practical and frank, but you can be really romantic at times. You are quite casual and it's easy for you to make friends. You think the world would be much easier if it was more simple, and wish your life was a straight line from the bottom to the top. "
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Dragon Ball Z",
        japanese_name: "ドラゴンボールZ",
        description: [
            {
                lang: "en",
                text: "You are a straightforward person who likes straightforward things and media. You believe that willpower is very important in life, and nothing can be solved without really determined willpower. You also think that evil people can do good things as long as their goal is good, and they are not harming anyone, and if your ability is sufficient, no task is impossible. You accept and respect a lot of types of people, as long as they're hardworking. You rarely think about the past and your mistakes."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a straightforward person who likes straightforward things and media. You don't like complicated things, so you have a problem with them. You think the world would be much easier if it was more simple, and wish your life was a straight line from the bottom to the top. You believe that willpower is very important in life, and nothing can be solved without really determined willpower. You also think that evil people can do good things as long as their goal is good, and they are not harming anyone, and if your ability is sufficient, no task is impossible. You accept and respect a lot of types of people, as long as they're hardworking. You rarely think about the past and your mistakes."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "JoJo's Bizarre Adventure",
        japanese_name: "ジョジョの奇妙な冒険",
        description: [
            {
                lang: "en",
                text: "You are a casual person, and it's easy for you to make friends. People think about you as an amusing person who laughs easily at the lamest jokes. You're very flexible and your only rule is 'Everything can change, except this rule'. You often wonder why people have to be so serious. When you're really serious, people know that some kind of disaster happened.",
                jobs: "Comedian, Presenter, Party Organizer, Salesman"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a casual person, and it's easy for you to make friends. People think about you as an amusing person who laughs easily at the lamest jokes. You're very flexible and your only rule is 'Everything can change, except this rule'. You often wonder why people have to be so serious. When you're really serious, people know that some kind of disaster happened. So people often have trust issues with you.",
                jobs: "Comedian, Presenter, Party Organizer, Salesman"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Gintama",
        japanese_name: "銀魂",
        description: [
            {
                lang: "en",
                text: "You are a casual person, and it's easy for you to make friends. People think about you as an amusing person who laughs easily at the lamest jokes. However, you're quite serious and focused when something really interests you, and that surprises people. You're basically flexible and you don't believe that there is a set standard of morals, it's always dependent on the situation. You like to show that you're cool and indifferent, but you're actually surprisingly warm and understanding.",
                jobs: "Comedian, Presenter, Marketer, Salesman"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a casual person, and it's easy for you to make friends. People think about you as an amusing person who laughs easily at the lamest jokes. However, you're quite serious and focused when something really interests you, and that surprises people. You're basically flexible and you don't believe that there is a set standard of morals, it's always dependent on the situation. You like to show that you're cool and indifferent, but you're actually surprisingly warm and understanding.",
                jobs: "Comedian, Presenter, Marketer, Salesman"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Attack on Titan - Shingeki no Kyojin",
        japanese_name: "進撃の巨人",
        description: [
            {
                lang: "en",
                text: "Although you may be positive, your worldview is basically pessimistic. You think there are a lot of people harbouring negative intents around, and you're good at sensing the bad intentions. You can smile at anyone, but you don't trust them easily.",
                jobs: "Salesman, Debt Retriever"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "Although you may be positive, your worldview is basically pessimistic. You think there are a lot of people harbouring negative intents around, and you're good at sensing the bad intentions. You can smile at anyone, but you don't trust them easily. You believe it's easier to advance in life if you can make friends based on benefits.",
                jobs: "Salesman, Debt Retriever"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Steins;Gate",
        japanese_name: "シュタインズ・ゲート",
        description: [
            {
                lang: "en",
                text: "You're basically a nerd, and it's hard for people to understand you. You can only make nerd friends, and you like to make nerd jokes. That makes it hard for you to find a lover. You have a sophisticated and deep understanding of the world, and you're quite romantic around nerds. Although you like to show off, you're basically quite shy. At some points in your life, you believed that the world is controlled by a secret force. You're really affected by small things that no one understands, but when things that scare normal people happen to you, you don't feel anything. Something like your neighbor won a lottery doesn't make you feel anything, but something like your microwave is 1 second slower than normal is a big deal.",
                jobs: "Programmer, Scientist, Writer, Architect"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You're basically a nerd, and it's hard for people to understand you. You can only make nerd friends, and you like to make nerd jokes. That makes it hard for you to find a lover. You have a sophisticated and deep understanding of the world, and you're quite romantic around nerds. Although you like to show off, you're basically quite shy. At some points in your life, you believed that the world is controlled by a secret force. You're really affected by small things that no one understands, but when things that scare normal people happen to you, you don't feel anything. Something like your neighbor won a lottery doesn't make you feel anything, but something like your microwave is 1 second slower than normal is a big deal.",
                jobs: "Programmer, Scientist, Writer, Architect"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "My Hero Academia",
        japanese_name: "僕のヒーローアカデミア",
        description: [
            {
                lang: "en",
                text: "You are a casual person, and it's easy for you to make friends. You are also a straightforward person who likes straightforward things and media. You believe that there is a path for you to advance in your life and your career, and that path is difficult, but it's a clear, pre-defined path. You're basically simple, practical and serious."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a casual person, and it's easy for you to make friends. You are also a straightforward person who likes straightforward things and media. You believe that there is a path for you to advance in your life and your career, and that path is difficult, but it's a clear, pre-defined path. You tend to ignore the sophisticated parts of that path because it's hard for you to understand them. You're basically simple, practical and serious."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Tokyo Revengers",
        japanese_name: "東京卍リベンジャーズ",
        description: [
            {
                lang: "en",
                text: "You're basically a calm and reasonable person who knows your limit. You really don't like to bother other people with your problems. People say that you're a little cold, but you're actually quite warm to people who are close to you. You make a clear, detailed plan before you try to do important things, but you also got a flexible mind to adapt to unexpected situations. Despite your success in reaching many goals of yours, sometimes you feel sad because the world is basically sorrow and full of bad people, and you think too much about that fact since you're a good person."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You're basically a calm and reasonable person who knows your limit. You really don't like to bother other people with your problems. People say that you're a little cold, but you're actually quite warm to people who are close to you. You make a clear, detailed plan before you try to do important things, but you also got a flexible mind to adapt to unexpected situations. Despite your success in reaching many goals of yours, sometimes you feel sad because the world is basically sorrow and full of bad people, and you think too much about that fact since you're a good person. You are also bad at making jokes, and when you're kidding, people often can't tell whether you're kidding or you're saying the truth."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Jujutsu Kaisen",
        japanese_name: "呪術廻戦",
        description: [
            {
                lang: "en",
                text: "You're a casual person, and it's easy for you to make friends. So you're not interested in personality quizzes like this one, and you believe there is no way they can be accurate, so you're just here because your friend tried it, or you're too bored. You're basically a cold person who are not very sensitive to personalities, that's why you should not gain anything by staying here."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You're a casual person, and it's easy for you to make friends. So you're not interested in personality quizzes like this one, and you believe there is no way they can be accurate, so you're just here because your friend tried it, or you're too bored. You're basically a cold person who are not very sensitive to personalities, that's why you should not gain anything by staying here."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Fruits Basket",
        japanese_name: "フルーツバスケット",
        description: [
            {
                lang: "en",
                text: "You're really sensitive, warm and gentle. You're also understanding and sophisticated. You know so much about people's feelings that sometimes you refrain from telling people your thinking, because you're afraid they'd be scared since you know too much about them. You understand that the world is complex and sadness is everywhere, but you choose to face it and move forward. You like to believe that good and evil is separated, and there is a clear set of morals to identify the good, but you know that the line between them is blurry. You are the type of person who enjoy little things in life, although you like to have a goal to strive for. You're very hardworking to reach that goal.",
                jobs: "Psychologist, Writer, Counselor"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You're really sensitive, warm and gentle. You're also understanding and sophisticated. You know so much about people's feelings that sometimes you refrain from telling people your thinking, because you're afraid they'd be scared since you know too much about them. You understand that the world is complex and sadness is everywhere, but you choose to face it and move forward. You like to believe that good and evil is separated, and there is a clear set of morals to identify the good, but you know that the line between them is blurry. You are the type of person who enjoy little things in life, although you like to have a goal to strive for. You're very hardworking to reach that goal.",
                jobs: "Psychologist, Writer, Counselor"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Sailor Moon",
        japanese_name: "美少女戦士セーラームーン",
        description: [
            {
                lang: "en",
                text: "You have better imagination than other people. You are a lovely and gentle person. You are very fixated when it comes to things you like, and you won't change your interest easily."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You have better imagination than other people. You are a lovely and gentle person. You are very fixated when it comes to things you like, and you won't change your interest easily."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Doraemon",
        japanese_name: "ドラえもん",
        description: [
            {
                lang: "en",
                text: "You have better imagination than other people, and you are quite knowledgeable. You have a lot of ideas. You don't expect things having to be the way you want them to be, and life is basically unpredictable. That's why you don't feel as angry as others when things aren't the way you want."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You have better imagination than other people, and you are quite knowledgeable. You have a lot of ideas. You don't expect things having to be the way you want them to be, and life is basically unpredictable. That's why you don't feel as angry as others when things aren't the way you want."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Pokemon",
        japanese_name: "ポケットモンスター",
        description: [
            {
                lang: "en",
                text: "You like to have a lot of pets to take care of. You believe in diversity and think more diversity means a better world, and you hate repetitive tasks. You are very fixated when it comes to things you like, and you won't change your interest easily."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You like to have a lot of pets to take care of. You believe in diversity and think more diversity means a better world, and you hate repetitive tasks. You are very fixated when it comes to things you like, and you won't change your interest easily."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Natsume's Book of Friends - Natsume Yuujinchou",
        japanese_name: "夏目友人帳",
        description: [
            {
                lang: "en",
                text: "You're a deep person who loves life and the world around you. You do not fear death and you are not afraid of being non-existent. You seem to be calm and distant, but you actually notices all the smallest details about people and you genuinely hope for world peace. You have limitless patience, and you feel that you can just sit still all day at a nice natural place. You always have too much to think about everything around you in a deep and sophisticated worldview, sometimes that makes you feel tired. You feel that you can accept anything in life though. You are very unique, and that ocassionally makes you feel hard to live because you're too different. You're also very easily distracted from your main goal. Sometimes you wish you could melt into the ocean and fade away."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You're a deep person who loves life and the world around you. You do not fear death and you are not afraid of being non-existent. You seem to be calm and distant, but you actually notices all the smallest details about people and you genuinely hope for world peace. You have limitless patience, and you feel that you can just sit still all day at a nice natural place. You always have too much to think about everything around you in a deep and sophisticated worldview, sometimes that makes you feel tired. You feel that you can accept anything in life though. You are very unique, and that ocassionally makes you feel hard to live because you're too different. You're also very easily distracted from your main goal. Sometimes you wish you could melt into the ocean and fade away."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Tsubasa: Reservoir Chronicle",
        japanese_name: "ツバサ-RESERVoir CHRoNiCLE-レザヴォア クロニクル-",
        description: [
            {
                lang: "en",
                text: "You are a warm and gentle introvert, but you love travelling. Freedom is quite important. Although you are quite positive and like to have friends, you always feel a little lonely, even when you're with your lover. When you travel, you can forget that feeling and you like that. You buy a lot of clothes, but you only wear the ones that feel familiar.",
                jobs: "Travel blogger, Writer, Singer"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You are a warm and gentle introvert, but you love travelling. Freedom is quite important. Although you are quite positive and like to have friends, you always feel a little lonely, even when you're with your lover. When you travel, you can forget that feeling and you like that. You buy a lot of clothes, but you only wear the ones that feel familiar.",
                jobs: "Travel blogger, Writer, Singer"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Cardcaptor Sakura",
        japanese_name: "カードキャプターさくら",
        description: [
            {
                lang: "en",
                text: "You're a sweet person who can't try not to be gentle even when you want that. You're a great mediator and you can make parties who are arguing become calmer with just your smile. You don't believe that purely evil people exist, and people do bad things for a reason. Sometimes you forget to take care of yourself while you care too much about others."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You're a sweet person who can't try not to be gentle even when you want that. You're a great mediator and you can make parties who are arguing become calmer with just your smile. You don't believe that purely evil people exist, and people do bad things for a reason. Sometimes you forget to take care of yourself while you care too much about others."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "xxxHolic",
        japanese_name: "×××HOLiC",
        description: [
            {
                lang: "en",
                text: "You are basically a good person, but you like to tell lies. Most of the time they are just harmless lies for good reasons, but that's not always true. You do not fear death and you are not afraid of being non-existent. You are also not afraid of the dark and you can walk at night without lights. You never say sweet words and think that sweet talkers are all liars. You don't really have a strong concept of self and you don't care what people think about you. You keep a distance from everyone, including friends and family.",
                jobs: "Programmer, Tomb Guard, Scientist, Novelist"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are basically a good person, but you like to tell lies. Most of the time they are just harmless lies for good reasons, but that's not always true. You do not fear death and you are not afraid of being non-existent. You are also not afraid of the dark and you can walk at night without lights. You never say sweet words and think that sweet talkers are all liars. You don't really have a strong concept of self and you don't care what people think about you. You keep a distance from everyone, including friends and family.",
                jobs: "Programmer, Tomb Guard, Scientist, Novelist"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "3-gatsu no Lion",
        japanese_name: "3月のライオン",
        description: [
            {
                lang: "en",
                text: "You've always felt that the world has a certain kind of depressing and dark vibes surrounding everything. Sometimes it gets into you and you become depressed. Although you believe that there are truly nice people, you're too soft and you get sad from random words they said. You always wanted to get stronger, but you think it's hard. You're really caring and understanding, but you're afraid of getting hurt, so you don't try to make friends. You have a dream of becoming a true artist, and you have truly beautiful ideals about art. The world is not as beautiful as you'd like it to be though.",
                jobs: "Psychologist, Writer, Counselor"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You've always felt that the world has a certain kind of depressing and dark vibes surrounding everything. Sometimes it gets into you and you become depressed. Although you believe that there are truly nice people, you're too soft and you get sad from random words they said. You always wanted to get stronger, but you think it's hard. You're really caring and understanding, but you're afraid of getting hurt, so you don't try to make friends. You have a dream of becoming a true artist, and you have truly beautiful ideals about art. The world is not as beautiful as you'd like it to be though.",
                jobs: "Psychologist, Writer, Counselor"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Mob Psycho 100",
        japanese_name: "モブサイコ100",
        description: [
            {
                lang: "en",
                text: "You're a quirky person who is strangely also serious. You really have the talent to realize the nature of things, instead of being distracted by their appearances. You have great imagination and logical thinking skills. You're quite calm and you're able to accept almost everything. Sometimes you think too deeply about things and waste time on them, although they're actually simple.",
                jobs: "Psychologist, Writer, Counselor"
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You're a quirky person who is strangely also serious. You really have the talent to realize the nature of things, instead of being distracted by their appearances. You have great imagination and logical thinking skills. You're quite calm and you're able to accept almost everything. Sometimes you think too deeply about things and waste time on them, although they're actually simple.",
                jobs: "Psychologist, Writer, Counselor"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Haikyuu!!",
        japanese_name: "ハイキュー!!",
        description: [
            {
                lang: "en",
                text: "You are a simple person who likes straightforward things. You're genuinely honest and nice, quite understanding and generous. It's very easy for you to get friends, and you're never calculating with them. You're very consistent when it comes to your goal."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a simple person who likes straightforward things. You're genuinely honest and nice, quite understanding and generous. It's very easy for you to get friends, and you're never calculating with them. You're very consistent when it comes to your goal. Your problem is that you're not good at understanding complex things, and you hate the headache that comes from them."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Monster",
        japanese_name: "モンスター",
        description: [
            {
                lang: "en",
                text: "You have always been fascinated by psychology, and you watched people's emotions and reactions a lot. You know so much about people's feelings that sometimes you refrain from telling people your thinking, because you're afraid they'd be scared since you know too much about them. Sometimes you go into a trance, that's when you can't hear anybody saying anything. You are able to fully understand the psychology of both good and evil people. You're basically a serious and hardworking person, and you don't care about spending money as you're very thrifty. You can stay calm in most of the bad situations. People like you because you're dependable, very organized and understanding."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You have always been fascinated by psychology, and you watched people's emotions and reactions a lot. You know so much about people's feelings that sometimes you refrain from telling people your thinking, because you're afraid they'd be scared since you know too much about them. Sometimes you go into a trance, that's when you can't hear anybody saying anything. You are able to fully understand the psychology of both good and evil people. You're basically a serious and hardworking person, and you don't care about spending money as you're very thrifty. You can stay calm in most of the bad situations. People like you because you're dependable, very organized and understanding."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Spirited Away",
        japanese_name: "千と千尋の神隠し",
        description: [
            {
                lang: "en",
                text: "You believe that growth is a difficult process, but you like to think about your growth as an exciting and challenging period. You think you can find beauty in the dirty and chaotic places. You are quite warm, gentle and understanding."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You believe that growth is a difficult process, but you like to think about your growth as an exciting and challenging period. You think you can find beauty in the dirty and chaotic places. You are quite warm, gentle and understanding."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Kingdom",
        japanese_name: "キングダム",
        description: [
            {
                lang: "en",
                text: "You have a long-term determined goal and you will reach it no matter what people say about that goal. You are very practical, so you will use any suitable method to reach it. You think there are a lot of people harbouring negative intents around, and you're good at sensing the bad intentions."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You have a long-term determined goal and you will reach it no matter what people say about that goal. You are very practical, so you will use any suitable method to reach it. You think there are a lot of people harbouring negative intents around, and you're good at sensing the bad intentions. Sometimes you're overconfident in your own abilities, and you end up making basic mistakes."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "To Your Eternity",
        japanese_name: "不滅のあなたへ",
        description: [
            {
                lang: "en",
                text: "You are basically like a ghost. You do not fear death and you are not afraid of being non-existent. You are very flexible and have a good façade and you're able to be whatever people like you to be. You also have great vision for the overall state of everything. You like to study about life and death itself. You think that the world is a little sad, but we have to accept that. You can stay calm in most of the bad situations, and you're very organized. "
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are basically like a ghost. You do not fear death and you are not afraid of being non-existent. You are very flexible and have a good façade and you're able to be whatever people like you to be. You also have great vision for the overall state of everything. You like to study about life and death itself. You think that the world is a little sad, but we have to accept that. You can stay calm in most of the bad situations, and you're very organized. Sometimes people think you're a little bland though. At the end of the day you wonder who you really are?"
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Great Teacher Onizuka",
        japanese_name: "グレート・ティーチャー・オニヅカ",
        description: [
            {
                lang: "en",
                text: "You are a patient and tolerable person, but you can also be very frank. You think you can change someone just by your efforts and consistence. Your problem is that girls don't really like you because you never say sweet words and you don't seem to be sophisticated."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a patient and tolerable person, but you can also be very frank. You think you can change someone just by your efforts and consistence. Your problem is that girls don't really like you because you never say sweet words and you don't seem to be sophisticated."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Princess Mononoke - Mononoke Hime",
        japanese_name: "もののけ姫",
        description: [
            {
                lang: "en",
                text: "You are a patient and deep person. You believe that the world has some basic laws and values, and if you abide by them, you will stay safe and well. People think you're a little cold, but you're actually quite warm and understanding. You can stay calm in most of the bad situations."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You are a patient and deep person. You believe that the world has some basic laws and values, and if you abide by them, you will stay safe and well. People think you're a little cold, but you're actually quite warm and understanding. You can stay calm in most of the bad situations."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "One-Punch Man",
        japanese_name: "ワンパンマン",
        description: [
            {
                lang: "en",
                text: "You're a frank person who don't care about your social status and your reputation in life. You believe that people can't be judged by their appearance. People are often scared when they take new challenges when they are not experienced enough, but you can face them like they're just normal things you do daily."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You're a frank person who don't care about your social status and your reputation in life. You believe that people can't be judged by their appearance. People are often scared when they take new challenges when they are not experienced enough, but you can face them like they're just normal things you do daily. Sometimes you're overconfident in your own abilities, and you end up making basic mistakes. You like to pretend that you're very mature, but you're actually a little childlish."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Boarding School Juliet - Kishuku Gakkou No Juliet",
        japanese_name: "寄宿学校のジュリエット",
        description: [
            {
                lang: "en",
                text: "You are a sweet and shy person. You're also very romantic and positive. You're the type of person who will do anything to make the person you like be happy."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You are a sweet and shy person. You're also very romantic and positive. You're the type of person who will do anything to make the person you like be happy. Your problem is that you have a lot of things you'd like to do, but you tend to do those things half-assedly and stop working on those when those are still not done. You know that real solutions for problems usually involve some kind of damage, so you try to avoid them and look for simple solutions that are less dangerous, but won't solve the problems."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Haruhi Suzumiya",
        japanese_name: "涼宮ハルヒ",
        description: [
            {
                lang: "en",
                text: "You're a quirky person, with great logical skills and good reflexes. You're really good at managing hard situations that requires you to constantly change your direction, such as being a coordinator or a footballer. You don't mind changing your plan at the last minute. "
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        original_description: [
            {
                lang: "en",
                text: "You're a quirky person, with great logical skills and good reflexes. You're really good at managing hard situations that requires you to constantly change your direction, such as being a coordinator or a footballer. You don't mind changing your plan at the last minute. People don't really feel that they can trust you to always be honest with them though."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "86 -Eighty Six-",
        japanese_name: "86ーエイティシックスー",
        description: [
            {
                lang: "en",
                text: "You're basically a delicate and honest person. You're a beauty seeker and you were always passionate about collecting art. You live for values that you believe they're righteous and true, although they are not necessarily morally right. People like you because you're dependable and cool. You really need and love silence and can't stand the noise. When you consider a person important, you will never betray that person."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You're basically a delicate and honest person. You're a beauty seeker and you were always passionate about collecting art. You live for values that you believe they're righteous and true, although they are not necessarily morally right. People like you because you're dependable and cool. You really need and love silence and can't stand the noise. When you consider a person important, you will never betray that person."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Miss Kobayashi's Dragon Maid",
        japanese_name: "小林さんちのメイドラゴン",
        description: [
            {
                lang: "en",
                text: "You seem to be a casual person, but you're actually serious, you're not strict though. You are quite selfless and you use your money to build your family, instead of personal desires. Although you're romantic, you're also practical. Your worldview is objective and quite mature."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You seem to be a casual person, but you're actually serious, you're not strict though. You are quite selfless and you use your money to build your family, instead of personal desires. Although you're romantic, you're also practical. Your worldview is objective and quite mature. Your problem is that you don't really have a goal, and you are often stuck doing what you're comfortable with although there are many things you'd like to try."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Vanitas no Carte",
        japanese_name: "ヴァニタスの手記",
        description: [
            {
                lang: "en",
                text: "Although you're basically a good person and you have no problem understanding complex things, you are not very strong emotionally and you have a quite convoluted way of thinking about relationships and the world, so sometimes you miss good opportunities in life and you tend to overthink things. You're very introverted, and you like to do things alone. You're easily simulated by sinful desire, and sometimes it's hard for you to distinguish between reality and fantasy. You like to pretend to be cool, but you're actually delicate."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "Although you're basically a good person and you have no problem understanding complex things, you are not very strong emotionally and you have a quite convoluted way of thinking about relationships and the world, so sometimes you miss good opportunities in life and you tend to overthink things. You're very introverted, and you like to do things alone. You're easily simulated by sinful desire, and sometimes it's hard for you to distinguish between reality and fantasy. You like to pretend to be cool, but you're actually delicate."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Kanojo mo Kanojo",
        japanese_name: "カノジョも彼女",
        description: [
            {
                lang: "en",
                text: "You are a good person who likes to tease people and have fun. Your best strength is your ability to ignore bad things in the world and just enjoy little things in life. That makes it difficult for you to reach true new heights and serious relationships. You're quite good at telling lies, and you like flashy things. You don't expect things having to be the way you want them to be, and life is basically unpredictable. That's why you don't feel as angry as others when things aren't the way you want."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You are a good person who likes to tease people and have fun. Your best strength is your ability to ignore bad things in the world and just enjoy little things in life. That makes it difficult for you to reach true new heights and serious relationships. You're quite good at telling lies, and you like flashy things. You don't expect things having to be the way you want them to be, and life is basically unpredictable. That's why you don't feel as angry as others when things aren't the way you want."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Rent-A-Girlfriend",
        japanese_name: "彼女、お借りします",
        description: [
            {
                lang: "en",
                text: "You are a kind and warm person. Most of the time, you can't tell lies so it's better to be honest. You are basically indecisive and you are often unable to take the initiative. You believe that goodwill is very important to reach big things in life. You tend to overthink things while missing the most important point. You believe that simplicity is the best, and you save all your money to wait for your chance."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "You are a kind and warm person. Most of the time, you can't tell lies so it's better to be honest. You are basically indecisive and you are often unable to take the initiative. You believe that goodwill is very important to reach big things in life. You tend to overthink things while missing the most important point. You believe that simplicity is the best, and you save all your money to wait for your chance."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    },
    {
        name: "Kaze Hikaru",
        japanese_name: "風光る",
        description: [
            {
                lang: "en",
                text: "People think you're quite delicate, but you're actually tougher and have stronger will than they thought. You like psychology and love studying people with complicated, contradicting traits. You like to have fun, but you can't stop thinking about sadness."
            },
            {
                lang: "vi",
                text: ""
            }
        ],
        is_fav: true,
        original_description: [
            {
                lang: "en",
                text: "People think you're quite delicate, but you're actually tougher and have stronger will than they thought. You like psychology and love studying people with complicated, contradicting traits. You like to have fun, but you can't stop thinking about sadness."
            },
            {
                lang: "vi",
                text: ""
            }
        ]
    }
]