import React, { useRef, useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import News from '../news'
import Disqus from './Disqus'
import Fans from '../fans'
import NewApp from "../App";
import Main from "./App";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

function Routing() {
    const router = useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);
    var url_string = window.location.href
    var url = new URL(url_string);
    var sub = url.searchParams.get("sub");
    var id = url.searchParams.get("id");
    setTimeout(() => {
        var path = `/${sub}`;
        if (id != null && id != '')
            path+= `?id=${id}`;
        if (sub != null && sub != '' && router.current && router.current.history)
            router.current.history.push(path)
    }, 50)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const downloadAPK = () => {
        document.title = "AnimeGuess - APK"
        window.location.href = "/animeguess.apk"
    }
    return (
        <>
            <Button
                id="demo-positioned-button"
                aria-controls="demo-positioned-menu"
                aria-haspopup="true"
                aria-expanded={menuOpen ? 'true' : undefined}
                onClick={handleClick}
            >
                <MenuIcon></MenuIcon>
            </Button>

            <Router ref={router} >
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div>
                        <Link style={{ color: "#000000", textDecoration: 'none' }} to={'/'} rel="noopener noreferrer" >
                            <MenuItem>Home</MenuItem>
                        </Link>
                        {/* <MenuItem onClick={openNew}>New Ver</MenuItem> */}
                        <Link style={{ color: "#000000", textDecoration: 'none' }} to={'/news'} rel="noopener noreferrer" >
                            <MenuItem>News</MenuItem>
                        </Link>
                    </div>
                </Menu>
                <img onClick={downloadAPK} style={{position: 'absolute', left: 70, top: 10}} src="/apk.png" width="80px" height="30px" alt="Install AnimeGuess on your phone"></img>
                <Switch>
                    <Route path="/new">
                        <NewApp />
                    </Route>
                    <Route path="/news">
                        <News />
                    </Route>
                    <Route path="/disqus">
                        <Disqus />
                    </Route>
                    <Route path="/fans">
                        <Fans />
                    </Route>
                    <Route path="/">
                        <Main />
                    </Route>
                </Switch>
            </Router>
        </>
    );
}


export default Routing;
