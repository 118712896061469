import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function Fans() {
  document.title = 'AnimeGuess - FAQ'
    return (
        <div>
      {/* <Container sx={{ display: "flex", justifyContent: "center" }}>
          <img src="/AG-logo-417.png" width={100} style={{textAlign: "center"}}></img>

            </Container> */}
          <Container>
                <Typography id="transition-modal-title" variant="h4" component="h2">
                  <p style={{ fontFamily: 'Hahmlet', textAlign: "center" }}>FAQ</p>
                </Typography>
                <div><b>Q: </b> Who created AnimeGuess?</div>
                <div style={{marginTop: 5}}><b>A: </b> AnimeGuess is the work of Sora Studio, led by me, basafish. I did the ReactJS coding and wrote the descriptions.</div>
                <br/>
                <div><b>Q: </b> I think I saw these descriptions somewhere.</div>
                <div style={{marginTop: 5, marginBottom: 5}}><b>A: </b> AnimeGuess is actually inspired by my meme on Reddit.</div>
                <img style={{marginBottom: 5}} width={700} src="/original.jpg" href="https://www.reddit.com/r/Animemes/comments/qa6y6w/your_personality_based_on_your_most_favorite/"></img>

                <div>Thanks, top comment posters! You helped me realize that my descriptions has some potential.</div>
                <br/>
                <div><b>Q: </b> How to contact you guys?</div>
                <div style={{marginTop: 5}}><b>A: </b> Currently those guys don't exist yet, haha. There is just basafish. You can contact basafish through <a style={{color: 'blue'}}>sorapenstudio@gmail.com</a>.</div>
                <br/>
                <div><b>Q: </b> What does <b style={{color:"#003399"}}>blue title</b> mean?</div>
                It means the description you see has Very High Accuracy.
                <br/>
                <br/>
                <div><b>Q: </b> What are some new features to be added to the site in the upcoming days?</div>
                These are on top of my head:
                <ul style={{listStyleType: 'circle'}}>
                  <li>Disqus comments on description popups.</li>
                  <li>Email subscription when there is a new feature/new anime.</li>
                  <li>Suitable jobs.</li>
                  <li>Email notification when there is someone who super-fav the same anime as you.</li>
                  <li>Romance tab, something like "Hunter X Hunter fans has great compatibility with Sailor Moon fans".</li>
                  <li>Login. Make your list of super-fav anime today and compare it with your list from last year, see the difference.</li>
                </ul>
                If you would like to see any feature, please send me an email!
                <br/>
                <br/>
                <div><b>Q: </b> How the [beep] can this thing be that accurate?</div>
                <div style={{marginTop: 5}}><b>A: </b> It's just my random guess! No magic behind it. Don't think too much about it!</div>
                <br/>
                <div><b>Q: </b> Can you add the [XYZ] anime?</div>
                <div style={{marginTop: 5}}><b>A: </b> It's my dream to include all the 20202 anime that exist (another random guess), but my brain can't include that much information in the near future. If you have an amazing suggestion, shoot me an email at <a style={{color: 'blue'}}>sorapenstudio@gmail.com</a> with this subject: "Addition Suggestion: [Your Title]".</div>
                <br/>
                <div><b>Q: </b> Can you add the [XYZ] manga/visual novel/Chinese stuff?</div>
                <div style={{marginTop: 5}}><b>A: </b> Despite the name, I tend to mistakenly add manga into the AnimeGuess collection if it appeals to me personally.</div>
                <br/>
          </Container>
        </div>
    )
}