import React, { useRef, useState, useEffect } from 'react';
import { pens } from '../personalities';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchIcon from '@mui/icons-material/Search';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FlatList from 'flatlist-react';
import { CheckMobile } from '../tools/swissknife'
import { handleImageSrc } from '../tools/swissknife'
import { Switch as SSwitch } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import InputAdornment from '@mui/material/InputAdornment';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';

const api_url = 'http://animeguess.com:6789/api'

const RedSwitch = styled(SSwitch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60vw',
  padding: 2,
  overflow: 'scroll',
  bgcolor: 'background.paper',
};

const mobileModalStyle = {
  position: 'absolute',
  top: '0%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: '80vw',
  padding: 2,
  overflow: 'scroll',
  bgcolor: 'background.paper',
}

function Main() {
  const [masterPens, setMasterPens] = useState([]);
  const [gridPens, setPens] = useState([]);
  const [query, setQuery] = useState("");
  const [openState, setOpen] = useState(false);
  const [isDangerous, setIsDangerous] = useState(false);
  const [currentPen, setPen] = useState(masterPens[0]);
  const [hasMoreItems, setMoreItems] = useState(true);
  const [lang, setLang] = useState("en");

  const pensRef = useRef();
  pensRef.current = masterPens;

  const stateRef = useRef();
  stateRef.current = gridPens;

  const queryRef = useRef();
  queryRef.current = query;

  const is_mobile = CheckMobile();


  useEffect(async () => {
    await getPens();    
    var url_string = window.location.href
    var url = new URL(url_string);
    var lang = url.searchParams.get("lang");
    if (lang && lang != "")
      setLang(lang);
    else
      setLang("en");
    document.addEventListener('scroll', onScroll);    
    handlePens();
  }, [])

  useEffect(() => {
    handlePens();
  }, [lang])

  useEffect(() => {
    if (query == "" || query == null)
      handlePens();
  }, [query])

  useEffect(() => {
    handlePens();
  }, [hasMoreItems])

   async function getPens() {
    // await axios.get(api_url +'/getPens')
    // .then(function (response) {
    //   console.log(response);
    //   setMasterPens(response.data);
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
    setMasterPens(pens);
  }

  function openDescription(isOpen) {
    setIsDangerous(false);
    setOpen(isOpen);

  }

  function handlePens() {
    const pens = pensRef.current;
    var more_pens = [];
    var gridpens = [...stateRef.current];
    var query = queryRef.current;

    if (query == "" || query == null) {
      gridpens.forEach(element => {
        more_pens.push(element);
      });
      var len = 0;
      len = more_pens.length;
      // var raw_pens = [];
      // var reverse_raw_pens = [];
      // var original_pens = pens;
      // reverse_raw_pens = original_pens.slice().reverse();
      // reverse_raw_pens.forEach(z=> raw_pens.push(z));
      // var fav_list = raw_pens.filter(x => x.is_fav);

      // fav_list.forEach(x => {
      //   raw_pens.unshift(x);
      // })
      console.log(pens);

      var fetch_size = 15;
      if (pens != null) {
        if (pens.length >= len) {
          if (pens.length >= len + fetch_size) {
            for (var i = len + 1; i <= len + (fetch_size - 1); i++) {
              more_pens.push(pens[i]);
            }
            setPens(more_pens);
          }
          else {
            for (var i = len + 1; i <= len + (pens.length - len - 1); i++) {
              more_pens.push(pens[i]);
            }
            setPens(more_pens);
          }
        }

      }
      if (more_pens.length < pens.length)
        setMoreItems(true)
      else
        setMoreItems(false)
    }
  }

  function renderSquare(pen, idx) {
    var width = 200;
    if (is_mobile)
      width = 100;
    function handleClick(pen) {
      setPen(pen);
      // if (!is_mobile)
      setOpen(true);
      // else
      // {
      // //   setMobileDesc(true);
      // window.scrollTo(0, 0)
      // }
    }

    var src = handleImageSrc(pen.name)
    return (
      <span
        key={idx}>
        <img onClick={() => handleClick(pen)}
          alt={pen.name}
          style={{ marginRight: 5, cursor: 'pointer' }} width={width} src={src}></img>
      </span>
    );
  }



  function onEnter(e) {
    if (e.keyCode == 13) {
      onSearch();
    }
  }


  function onSearch() {
    var real_query = query.toLowerCase();
    const pens = pensRef.current;

    if (real_query != "") {
      var newPens = [];
      pens.forEach(element => {

        if (element.name.toLowerCase().includes(real_query))
          newPens.push(element);
        else if (element.japanese_name.toLowerCase().includes(real_query))
          newPens.push(element);
      });
      setPens(newPens);
    }
  }

  function setDangerous() {
    if (isDangerous)
      setIsDangerous(false);
    else
      setIsDangerous(true);

  }

  function changeLikeCount(name, number) {
    var grid_pens = stateRef.current;
    var pen = grid_pens.find(x => x.name == name);

      axios.post(api_url + '/changeLikedCount', {name: name, amount: number})
      .then(function (response) {
        console.log(response);
        setMasterPens(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    if (pen.liked_count == null)
      pen.liked_count = 0;

    if (pen != null)
      pen.liked_count = pen.liked_count + number;
    
    if (number == 1)
    {
      pen.liked = true;
      document.title = 'Liked AnimeGuess'
    }
    else
      pen.liked = false;

    setPen(pen);
    setPens([...grid_pens]);
    setOpen(false);
    setOpen(true);
  }

  function changeTitle(prefix) {
    document.title = prefix + ' - AnimeGuess'
  }

  function PenContent(props) {
    var domain = window.location.hostname;
    domain = domain.replaceAll('/', '')
    var pen = props.pen;
    var pen_id = "";
    if (pen.name != null)
      pen_id = pen.name.replaceAll('?', '').replaceAll('&', '');
    var color = '#000'
    if (pen.is_fav)
      color = '#003399'
    var width = 300;
    var img_col = 4;
    var text_col = 8;
    if (is_mobile) {
      img_col = 0;
      text_col = 12;
    }
    var desc = [
      {
        lang: "en",
        text: ""
      }
    ]
    if (pen.description != null) {
      var desc = pen.description.find(x => x.lang == lang);
      if (pen.original_description != null) {
        if (isDangerous)
          desc = pen.original_description.find(x => x.lang == lang);
      }
    }
    else {
      if (pen.original_description != null) {
        if (isDangerous)
          desc = pen.original_description.find(x => x.lang == lang);
      }
    }
    if (desc != null)
      pen.showDescription = desc.text;
    var src = handleImageSrc(pen.name);
    document.title = pen.name;
    var dangerous_msg = "";
    if (isDangerous)
      dangerous_msg = "Secret - "
    document.title = dangerous_msg + document.title;
    return (
      <Container sx={{
        display: "flex",
        justifyContent: "center"
      }}><Fade in={props.open}>
          <Box
            sx={props.style}
          >
            <Container sx={{
              display: "flex",
              justifyContent: "center"
            }}>
              {is_mobile ? <img src={src} alt={pen.name} style={{ maxWidth: '100%', height: 'auto', textAlign: "center" }}></img> : <></>}
            </Container>
            <Grid container spacing={2}>
              <Grid item xs={img_col}>
                {!is_mobile ? <img src={src} alt={pen.name} style={{ maxWidth: '100%', height: 'auto' }}></img> : <></>}
              </Grid>
              <Grid item xs={text_col}>
                <div style={{ padding: 10, borderBottom: "0.5px solid #bbb", textAlign: 'center' }}>

                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    <i style={{ fontFamily: 'Hahmlet', color: color }}>{pen.name}</i>
                  </Typography>
                  <Container sx={{ display: 'inline-block' }} >
                    <FormControlLabel
                      value="start"
                      control={<RedSwitch inputProps={{ 'aria-label': '' }} onChange={() => setDangerous()} size={'small'} checked={isDangerous} />}
                      label={<Typography sx={{ fontSize: 10 }}>
                        {pen.japanese_name}
                      </Typography>}
                      labelPlacement="start"
                    />


                  </Container>

                </div>
                <Typography id="transition-modal-description" sx={{ fontFamily: 'Spectral', mt: 2, textAlign: 'justify', textJustify: 'inter-word' }}>
                  {pen.showDescription}
                </Typography>
                <hr style={{ borderTop: "0.5px solid #bbb", marginTop: 15 }} />
                <div style={{ marginTop: 20 }}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item style={{ marginRight: 5 }}>
                      {pen.liked ?
                        <ThumbUpIcon onClick={() => changeLikeCount(pen.name, -1)} sx={{ color: '#2832C2' }} />
                        :
                        <ThumbUpAltOutlinedIcon onClick={() => changeLikeCount(pen.name, 1)}  sx={{ color: '#2832C2' }} />
                      }
                    </Grid>
                    <Grid item style={{ marginRight: 10 }}>
                      {pen.liked_count}
                    </Grid>
                    <a onClick={() => changeTitle('Facebook Share')} href="https://www.facebook.com/sharer/sharer.php?u=https://animeguess.com" target="_blank" rel="noopener">
                      <img src="/facebook.png" width="50px" height="22px" alt="Share on Facebook"></img>
                    </a>
                    <a onClick={() => changeTitle('Twitter Share')} style={{ marginLeft: 10 }} href="https://twitter.com/share?url=https://animeguess.com" target="_blank" rel="noopener">
                      <img src="/twitter.png" width="50px" height="22px" alt="Share on Twitter"></img>
                    </a>
                    <a href={'https://' + domain + '/index.html?sub=disqus&id=' + pen_id} target="_blank" rel="noopener noreferrer" >

                      {/* <a style={{ marginLeft: 10 }} href={'/disqus?id=' + pen_id} target="_blank" rel="noopener"> */}
                      <img style={{ marginLeft: 10 }} src="/comment.png" width="50px" height="22px" alt="Disqus comment"></img>
                      {/* </a> */}
                    </a>
                    <img alt="close" style={{ position: 'absolute', right: 5 }} onClick={() => setOpen(false)} src="/close.png" width="50px" height="22px"></img>

                  </Grid>
                </div>
                <div>

                </div>
                {/* <Typography id="transition-modal-note" sx={{ position: "absolute", left: 10, bottom: 10, fontSize: 10 }}>
            『Description is only accurate when {pen.name} is your most favorite anime <b>of all time</b>!』
            </Typography> */}
              </Grid>
            </Grid>
          </Box>
        </Fade >
      </Container >)
  }


  function PenDescription(props) {
    const handleOpen = () => props.setOpen(true);
    const handleClose = () => props.setOpen(false);
    var pen = props.pen;
    var style = modalStyle;
    if (is_mobile)
      style = mobileModalStyle;
    return (
      <div>
        <Modal
          id="pen-description"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{ overflow: 'scroll' }}
        >
          <PenContent pen={pen} open={true} style={style}></PenContent>
        </Modal>
      </div>
    );
  }

  const onScroll = (e) => {
    var scroll = e.target.scrollingElement;
    const { scrollTop, scrollHeight, clientHeight } = scroll;
    if (scrollTop + clientHeight >= scrollHeight * 90 / 100) {
      handlePens();
    }
  };

  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
      <link href="https://fonts.googleapis.com/css2?family=Hahmlet:wght@600&family=Spectral:wght@300&family=Zen+Kurenaido&display=swap" rel="stylesheet"></link>

      {/* <img onClick={() => handleLang('ja')} src={'/japan.png'} height={20} width={30} style={{ float: 'right', marginLeft: 5 }}></img> */}
      {/* <img onClick={() => handleLang('en')} src={'/uk.png'} height={20} width={30} style={{ float: 'right', marginLeft: 5 }}></img>
      <img onClick={() => handleLang('vi')} src={'/vietnam.jpg'} height={20} width={30} style={{ float: 'right', marginLeft: 5 }}></img> */}


      <Container>
        <div style={{ fontFamily: 'Hahmlet', textAlign: "center", marginTop: 10, fontSize: 22 }}>
          <div>Welcome to <a href="https://animeguess.com">animeguess.com</a></div>
        </div>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          {lang == "vi" ?
            <div>Chọn <b>anime ngươi thích nhất từ trước đến nay...</b> ta sẽ đoán tính cách ngươi!</div>
            : <div>Pick your <b>most favorite anime of all time...</b> and I'll guess your personality!
            </div>

          }
        </div>
        <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>『Description is only accurate when it is your most favorite anime <b>of all time</b>!』</div>
        <Container sx={{ display: "flex", justifyContent: "center", fontSize: 20, marginTop: 5 }}>You can search right here...</Container>

        <Container sx={{ display: "flex", justifyContent: "center" }}>
          <TextField placeholder={'Search your favorite anime here...'}
            sx={{ width: "50vw", maxWidth: 550, display: "flex", justifyContent: "center", marginBottom: 3 }}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => onEnter(e)}
            style={{ marginTop: 20 }}
            size="small"
            label={<SearchIcon />}
            id="anime-name"
            variant="outlined"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                    <ArrowRightIcon onClick={onSearch} />
                  </IconButton>
                </InputAdornment>
            }} />
        </Container>
        <Container sx={{ display: "flex", justifyContent: "center", fontSize: 20, marginBottom: 5 }}>Or browse the list below....</Container>

      </Container>

      <div onScroll={() => onScroll()}>
        <ul marginTop={10}>
          <FlatList
            list={gridPens}
            renderItem={renderSquare}
            renderWhenEmpty={() => <div>The author's imagination was unable to cover this mysterious area. Maybe you can check back later?</div>}
          />
        </ul>
      </div>

      <PenDescription open={openState} pen={currentPen} setOpen={openDescription}></PenDescription>
    </>

  );
}

export default Main;
